import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {Button, Container, Dropdown, Grid, Icon, List, Table} from "semantic-ui-react";
import {useLocation, useParams, useSearchParams, useNavigate} from "react-router-dom";
import {locationReportAction, orderReportAction} from "../../actions/reportAction"
import {useDispatch} from "react-redux";
import Chart from "react-apexcharts";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import {cellToBoundary, cellToLatLng, latLngToCell} from "h3-js";
import {toast} from "react-toastify";
import {
  checkCookie,
  formatErrors, LIMIT_FREE_SERVICE_USE, monthsShort,
  ROLE_ADMIN,
  ROLE_MANAGER,
  ROLE_SUBSCRIBER,
  ROLE_SUBSCRIBER_REPORTS,
  ROLE_USER,
  setCookie,
  trafficGradient
} from "../../constants";
import {getUser, storeUser} from '../../actions/loginAction';
import Footfall from "./accordion/Footfall";
import Demographics from "./accordion/Demographics";
import Competitors from "./accordion/Competitors";
import Amenities from "./accordion/Amenities";
import {myReportsAction} from "../../actions/ordersAction";
import Paging from "../../utils/Paging/Paging";
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Helmet} from 'react-helmet';
import Consultation from "./Consultation";
import {Track} from "../../utils/Overlay/Track";
//import WelcomePasswordChange from "../place-map/utils/WelcomePasswordChange";
import { Wrapper } from "@googlemaps/react-wrapper";
import { ReportPricePulse } from './tabs/ReportPricePulse';
import {getDeliveryZoneAction} from "../../actions/placeMapFootfallAction";
import LoginWidget from "../login/popup/LoginWidget";
import DailyFootfallAverage from "./parts/DailyFootfallAverage";
import DateChanger from "./parts/DateChanger";
import LocationReportNavigation from "./parts/LocationReportNavigation";
import RealEstateReport from "./tabs/RealEstateReport";
import Snapshot from "./tabs/Snapshot";
import CompetitorsTab from "./tabs/Competitors";
import DemographicsTab from "./tabs/Demographics";

function LocationReport() {
  const {reportId} = useParams();
  const profile = getUser();

  const gKey = "AIzaSyDcKjstulHew4HAkOa3pMM33EtPIaVqYoU";

  const [requestParams] = useSearchParams();

  const mapCompetitors = useRef(null);
  const mapCompetitorsContainer = useRef(null);

  const mapFootfall = useRef(null);
  const mapFootfallContainer = useRef(null);

  const mapAmenities = useRef(null);
  const mapAmenitiesContainer = useRef(null);

  const mapPricing = useRef(null);
  const mapPricingContainer = useRef(null);

  const mapDelivery = useRef(null);
  const mapDeliveryContainer = useRef(null);

  const [initPano, setInitPano] = useState(false);
  const panoramaContainer = useRef({
    pano: null,
    map: null,
  });

  const cuContainer = useRef({
    container: null,
    map: null,
    pop: null,
  });

  const location = useLocation();

  const [isAuth, setIsAuth] = useState(false);
  const [searchParams] = useSearchParams();

  const [myReportsFilter, setMyReportsFilter] = useState(null)

  //const [footfallAvgDayWeekend, setFootfallAvgDayWeekend] = useState(null);
  //const [footfallAvgDayWorkday, setFootfallAvgDayWorkday] = useState(null);
  const [footfallAvgDayAll, setFootfallAvgDayAll] = useState(null);
  const [avgWeekend, setAvgWeekend] = useState(0);
  const [avgWorkday, setAvgWorkday] = useState(0);
  const [footfallAvgWeek, setFootfallAvgWeek] = useState(null);
  const [footfallMonthTotalAvg, setFootfallMonthTotalAvg] = useState(0)
  const [footfallMonth, setFootfallMonth] = useState(null);

  //const [footfallAvgDayWeekendN, setFootfallAvgDayWeekendN] = useState(null);
  //const [footfallAvgDayWorkdayN, setFootfallAvgDayWorkdayN] = useState(null);
  const [footfallAvgDayAllN, setFootfallAvgDayAllN] = useState(null);

  const [footfallAvgDayByDay, setFootfallAvgDayByDay] = useState(null)
  const [footfallAvgDayByDayN, setFootfallAvgDayByDayN] = useState(null)

  const showPano = true;//[ROLE_USER, ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_REPORTS, ROLE_ADMIN].indexOf(profile.role_id) >= 0;
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const [ffDay, setFfDay] = useState(0);
  const [ffDayN, setFfDayN] = useState(0);

  const [dGeo, setDGeo] = useState("");

  const ffDayList = [
    {key: 0, value: 0, text: "All days"},
    {key: 1, value: 1, text: "Monday"},
    {key: 2, value: 2, text: "Tuesday"},
    {key: 3, value: 3, text: "Wednesday"},
    {key: 4, value: 4, text: "Thursday"},
    {key: 5, value: 5, text: "Friday"},
    {key: 6, value: 6, text: "Saturday"},
    {key: 7, value: 7, text: "Sunday"},
  ];

  const [macId, setMacId] = useState(0);
  const [bkId, setBkId] = useState(0);

  const [avgWeekendN, setAvgWeekendN] = useState(0);
  const [avgWorkdayN, setAvgWorkdayN] = useState(0);
  const [footfallAvgWeekN, setFootfallAvgWeekN] = useState(null);
  const [footfallMonthTotalAvgN, setFootfallMonthTotalAvgN] = useState(0)
  const [footfallMonthN, setFootfallMonthN] = useState(null);

  //const [totalInRadius, setTotalInRadius] = useState(0);
  const [ageReport, setAgeReport] = useState(null);
  //const [ageTotal, setAgeTotal] = useState(1);
  const [genderMale, setGenderMale] = useState(50);
  const [genderFemale, setGenderFemale] = useState(50);
  const [relationShipReport, setRelationshipReport] = useState(null);
  const [medianIncomeRate, setMedianIncomeRate] = useState("n/a");
  const [areaWealth, setAreaWealth] = useState("");
  const [medianIncome, setMedianIncome] = useState(null);
  const [crimeRateRating, setCrimeRateRating] = useState("n/a");
  const [crimeTypes, setCrimeTypes] = useState([]);
  const [competitors, setCompetitors] = useState([]);

  const [cuCompetitors, setCUCompetitors] = useState([]);

  const [subway, setSubway] = useState([]);
  const [subwayCnt, setSubwayCnt] = useState(0);
  const [schools, setSchools] = useState([]);
  const [schoolsCnt, setSchoolsCnt] = useState(0);
  const [colleges, setColleges] = useState([]);
  const [collegesCnt, setCollegesCnt] = useState(0);
  const [nurseries, setNurseries] = useState([]);
  const [nurseriesCnt, setNurseriesCnt] = useState(0);
  //const [businessCenters, setBusinessCenters] = useState([]);
  //const [businessCentersCnt, setBusinessCentersCnt] = useState(0);
  const [grocery, setGrocery] = useState([]);
  const [groceryCnt, setGroceryCnt] = useState(0);
  const [supermarkets, setSupermarkets] = useState([]);
  const [supermarketsCnt, setSupermarketsCnt] = useState(0);
  //const [doctors, setDoctors] = useState([]);
  //const [doctorsCnt, setDoctorsCnt] = useState(0);
  //const [pharm, setPharm] = useState([]);
  //const [pharmCnt, setPharmCnt] = useState(0);
  const [postOffices, setPostOffices] = useState([]);
  const [postOfficesCnt, setPostOfficesCnt] = useState(0);
  const [malls, setMalls] = useState([]);
  const [mallsCnt, setMallsCnt] = useState(0);
  const [banks, setBanks] = useState([]);
  const [banksCnt, setBanksCnt] = useState(0);
  const [atm, setAtm] = useState([]);
  const [atmCnt, setAtmCnt] = useState(0);

  const [footfallMapData, setFootfallMapData] = useState({});
  const [postcodePolygon, setPostcodePolygon] = useState({});
  const [bounds, setBounds] = useState([]);
  const [subPostcode, setSubPostcode] = useState(null);

  //const [bicycle, setBicycle] = useState([]);
  //const [bicycleCnt, setBicycleCnt] = useState(0);
  //const [motorcycle, setMotorcycle] = useState([]);
  //const [motorcycleCnt, setMotorcycleCnt] = useState(0);
  const [parking, setParking] = useState([]);
  const [parkingCnt, setParkingCnt] = useState(0);
  const [trainstation, setTrainstation] = useState([]);
  const [trainstationCnt, setTrainstationCnt] = useState(0);

  const [ethnicReport, setEthnicReport] = useState([]);
  const [ethnicTotal, setEthnicTotal] = useState(0);
  const [religionReport, setReligionReport] = useState([]);
  const [religionTotal, setReligionTotal] = useState(0);

  const [myReports, setMyReports] = useState([]);
  const [myReportsTotal, setMyReportsTotal] = useState(0);

  //const [showWelcome, setShowWelcome] = useState(location.state !== null && location.state.hasOwnProperty("welcome"));

  const [htag, setHtag] = useState(Math.random());

  const [data, setData] = useState({
    postcode: '',
    hex: '',
    lat: 0,
    lng: 0,
    year: 0,
    month: 0,
    address: '',
    footfall_request: false,
    competitors_category: 0,
  });
  const [ready, setReady] = useState(false);
  const [initGeocoder, setInitGeocoder] = useState(false);
  const [timings, setTimings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);

  const [showPromo, setShowPromo] = useState(false);
  const [showPromo2, setShowPromo2] = useState(true);
  const [competitorsCategory, setCompetitorsCategory] = useState(0);

  const [displayMode, setDisplayMode] = useState("footfall");
  const [placeName, setPlaceName] = useState("");

  // const [brandPoints, setBrandPoints] = useState(null);
  // const [brandItems, setBrandItems] = useState(null);

  const [pricingReport, setPricingReport] = useState(null);
  const [pricingMenuItems, setPricingMenuItems] = useState(null);

  const [tabs, setTabs] = useState(null);

  const [reportNum, setReportNum] = useState(null);
  const [reportSess, setReportSess] = useState(null);

  const [realEstateReport, setRealEstateReport] = useState(null);
  const [realEstateTab, setRealEstateTab] = useState(null);
  const [realEstateLocation, setRealEstateLocation] = useState("")

  const [avgFootfall, setAvgFootfall] = useState(null);

  const [cuFilter, setCuFilter] = useState({types: [], radius: 100, report_num: "", page: 1});
  const [cuTypes, setCuTypes] = useState([]);
  const [useCU, setUseCU] = useState(false);

  const [oc21, setOc21] = useState([]);

  const [dates] = useState([
    {month: 12, year: 2022, name: "December 2022"}, {month: 1, year: 2023, name: "January 2023"},
    {month: 2, year: 2023, name: "February 2023"}, {month: 3, year: 2023, name: "March 2023"}, {month: 4, year: 2023, name: "April 2023"},
    {month: 5, year: 2023, name: "May 2023"}, {month: 6, year: 2023, name: "June 2023"}, {month: 7, year: 2023, name: "July 2023"},
    {month: 8, year: 2023, name: "August 2023"}, {month: 9, year: 2023, name: "September 2023"}, {month: 10, year: 2023, name: "October 2023"},
    {month: 11, year: 2023, name: "November 2023"}, {month: 12, year: 2023, name: "December 2023"},
  ]);
  const [activeDate, setActiveDate] = useState(dates.length - 1);
  const gbBbox = [-8.585350424507451, 49.89060631678373, 1.762474098318385, 60.85571099962073];

  const [footfallVisitationAvg, setFootfallVisitationAvg] = useState({
    WorkdayT: 0, WeekendT: 0, DayT: 0,
    WorkdayR: 0, WeekendR: 0, DayR: 0,
    WorkdayW: 0, WeekendW: 0, DayW: 0,
  });

  const [geocoderMode, setGeocoderMode] = useState(0);
  const geocoderMenu = [
    {value: 0, text: "Address", title: "Search: Address"},
    {value: 1, text: "Postcode", title: "Search: Postcode"},
  ];

  const [priceLevel, setPriceLevel] = useState("0/5");

  const [inRadius, setInRadius] = useState({'p5000': 0, 'p1000': 0, 'p500': 0, 'p100': 0, 'b5000': 0, 'b1000': 0, 'b500': 0, 'b100': 0})

  const tabFootfall = 0;
  const tabTraffic = 1;
  const tabDemographic = 2;
  const tabAmenities = 3;
  const tabCompetitors = 4;
  const tabPanorama = 5;
  const tabPricing = 6;
  const tabDelivery = 7;
  const tabRealEstate = 8;
  const tabSnapshot = 9;

  const avgDayMultiplier = 1.05;

  const showDelivery = true;//profile.role_id === ROLE_ADMIN;

  const ffLayer = "footfall";
  const filterName = "m_LRMyReportsFilter";

  const [freeUses, setFreeUses] = useState(null);

  const [snapshot, setSnapshot] = useState(null);

  const [tab, setTab] = useState(tabSnapshot);
  const advCookie = checkCookie('advSub') !== "";
  const advCookie2 = checkCookie('advSub2') !== "";
  const dispatch = useDispatch();

  const compTypes = [
    {key: 0, value: 0, text:"Restaurants"},
    //{key: 1, value: 4, text:"Cafes"},
    //{key: 2, value: 10, text:"Burgers"},
    //{key: 3, value: 11, text:"Pizza"},
    //{key: 4, value: 12, text:"Sushi"},
    //{key: 5, value: 13, text:"Indian"},
    //{key: 6, value: 14, text:"Mexican"},
    {key: 7, value: 5, text:"Clothing stores"},
    {key: 9, value: 3, text:"Beauty salons"},
    {key: 10, value: 20, text:"Liquor stores"},
    {key: 14, value: 1029, text:"Hospital"},
    {key: 15, value: 1041, text:"Dentist"},
  ];

  const allTypes = [
    {key: 0, value: 0, text:"Restaurants"},
    //{key: 1, value: 1, text:"Bakery"},
    //{key: 2, value: 2, text:"Bar"},
    {key: 3, value: 3, text:"Beauty salons"},
    //{key: 4, value: 4, text:"Cafes"},
    {key: 5, value: 5, text:"Clothing stores"},
    //{key: 6, value: 6, text:"Meal delivery"},
    //{key: 7, value: 7, text:"Meal takeaway"},
    //{key: 8, value: 10, text:"Burgers"},
    //{key: 9, value: 11, text:"Pizza"},
    //{key: 10, value: 12, text:"Sushi"},
    //{key: 11, value: 13, text:"Indian"},
    //{key: 12, value: 14, text:"Mexican"},
    {key: 13, value: 20, text:"Liquor stores"},
    {key: 14, value: 1029, text:"Hospital"},
    {key: 15, value: 1041, text:"Dentist"},
  ]


  let SearchCategory = function(id) {
    for ( let obj of allTypes) {
      if (obj.value === id) {
        return obj.text;
      }
    }

    return "-";
  }

  mapboxgl.accessToken = 'pk.eyJ1Ijoic3Nlcmd5IiwiYSI6ImNsOHU5enNjbTAyMGQzcHJ4ODlsanNpNHgifQ.NXh_OvvnBFO_uArBg676IA';

  let geocoder = useRef(null);

  useEffect(() => {
    if (!!reportNum && !profile.id) {
      setShowLoginPopup(true);
    }

    if (!!reportNum) {
      setCuFilter({...cuFilter, 'report_num': reportNum});
    }
  }, [reportNum]);

  useEffect(() => {
    if (geocoder.current !== null) {
      geocoder.current.options.types = geocoderMode===0?"address":"postcode";
      ClearGeocoder();
      geocoder.current._clear();
    }
  }, [geocoderMode]);

  useEffect(() => {
    setHtag(Math.random());
    if (loading) {
      document.getElementsByTagName("body")[0].classList.add("active-loader");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("active-loader");
    }
  }, [loading]);

  useEffect(() => {
    if (data.year !== 0 && data.month !== 0 && data.address !== "") {
      data.year = dates[activeDate].year;
      data.month = dates[activeDate].month;
      data.footfall_request = true;

      if (searchParams.get('sess') !== null && searchParams.get('sess') !== '') {
        GetReports(searchParams.get('sess'), data);
      } else {

        if (!!reportNum) {
          data.report_id = reportNum;
        }

        GetReports('', data);
      }
    }
  }, [activeDate])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tab]);

  useEffect(() => {

    if (tab !== tabCompetitors || data.lng === 0 || data.lat === 0) return;
    if (mapCompetitors.current && !useCU) {
      document.getElementById("competitors-map-box").replaceWith(mapCompetitors.current.getContainer());
      mapCompetitors.current.resize()
    } else if(cuContainer.current.map && useCU) {
      document.getElementById("competitors-map-box").replaceWith( cuContainer.current.map.getContainer());
      cuContainer.current.map.resize()
    } else if(!useCU) {
      initCompetitorsMap(data.lat, data.lng);
    }
  }, [tab, data])

  useEffect(() => {
    if (tab !== tabFootfall || data.lng === 0 || data.lat === 0 || !ready || footfallMapData === {}) return;
    if (mapFootfall.current) {
      document.getElementById("footfall-map-box").replaceWith(mapFootfall.current.getContainer());
      mapFootfall.current.resize();
    } else {
      initFootfallMap(...cellToLatLng(latLngToCell(data.lat, data.lng, 10)));
    }
  }, [tab, data, ready, footfallMapData])

  useEffect(() => {
    if (tab !== tabAmenities || data.lng === 0 || data.lat === 0) return;
    if (mapAmenities.current) {
      document.getElementById("amenities-map-box").replaceWith(mapAmenities.current.getContainer());
      mapAmenities.current.resize();
    } else {
      initAmenitiesMap(data.lat, data.lng);
    }
  }, [tab, data])

  useEffect(() => {
    if (tab !== tabPricing || data.lng === 0 || data.lat === 0) return;
    if (mapPricing.current) {
      document.getElementById("pricing-map-box").replaceWith(mapPricing.current.getContainer());
      mapPricing.current.resize();
    } else {
      initPricingMap(data.lat, data.lng, macId, bkId);
    }
  }, [tab, data])

  useEffect(() => {
    if (!showDelivery || tab !== tabDelivery || data.lng === 0 || data.lat === 0) return;
    if (mapDelivery.current) {
      document.getElementById("delivery-map-box").replaceWith(mapDelivery.current.getContainer());
      mapDelivery.current.resize();
    } else {
      setLoading(true);

      dispatch(getDeliveryZoneAction({lat: data.lat, lng: data.lng})).then((res) => {
        let driving = JSON.parse(res.driving);
        let walking = JSON.parse(res.walking);
        let cycling = JSON.parse(res.cycling);

        initDeliveryMap(data.lat, data.lng, driving, cycling, walking);
        setLoading(false);
      }).catch((err) => {
        setLoading(false);

        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });

    }
  }, [tab, data])

  useEffect(() => {
    if (!!requestParams.get('hex') && !!data && data.lat !== 0 && data.lng !== 0) {
      GetReports('', data, -1, !!requestParams.get('from_map'));
    }
  }, [data]);

  function HandleCloseLoginPopup() {
    setShowLoginPopup(false);
  }

  function HandleCuFilter(field, value) {
    setCuFilter({...cuFilter, [field]: value});
  }

  const handleChangeFilter = (value, name) => {
    if (name === "page") {
      setMyReportsFilter({...myReportsFilter, page: value});
    } else if (name === "sort") {
      setMyReportsFilter({
        ...myReportsFilter,
        sort: value,
        sortd: myReportsFilter.sort === value ? (myReportsFilter.sortd === "asc" ? "desc" : "asc") : "asc",
        page: 1
      });
    } else if (name) {
      setMyReportsFilter({...myReportsFilter, [name]: value, page: 1});
    } else {
      setMyReportsFilter({...myReportsFilter});
    }
  };

  /*
  function CalcAvgToText(num) {
    if (num < 5000) {
      return "Low";
    } else if (num < 10000) {
      return "Medium";
    } else if (num < 20000) {
      return "High"
    } else {
      return "Very High"
    }
  }*/

  function initDeliveryMap(lat, lng, driving, cycling, walking) {
    mapDelivery.current = new mapboxgl.Map({
      container: mapDeliveryContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: 12,
    });

    mapDelivery.current.on('load',() => {
      let marker = new mapboxgl.Marker({color: "#4668F2"}).setLngLat([lng, lat]).addTo(mapDelivery.current);

      mapDelivery.current.addSource("delivery", {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      mapDelivery.current.addLayer({
        'id': "delivery-line",
        'type': 'line',
        'source': "delivery",
        'layout': {},
        'paint': {
          'line-color': 'rgba(115,21,48,0.45)',
          'line-width': 2
        }
      });


      mapDelivery.current.addLayer({
        'id': "delivery-fill",
        'type': 'fill',
        'source': "delivery", // reference the data source
        'layout': {},
        'paint': {
          'fill-color': 'rgba(140,73,96,0.45)',
          'fill-opacity': 0.5
        }
      });

      let jsonData = {
        "type": "geojson",
        "data": {
          "type": "FeatureCollection",
          "features": []
        }
      };

      jsonData.data.features.push({
        "type": "Feature",
        "geometry": driving
      });
      jsonData.data.features.push({
        "type": "Feature",
        "geometry": walking
      });
      jsonData.data.features.push({
        "type": "Feature",
        "geometry": cycling
      });

      let src = mapDelivery.current.getSource("delivery");

      if (typeof src !== 'undefined') {
        src.setData(jsonData.data);
      }

    });
  }

  function initFootfallMap(lat, lng) {
    mapFootfall.current = new mapboxgl.Map({
      container: mapFootfallContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: 13,
    });

    mapFootfall.current.on('load',() => {
      if (geocoderMode === 0 || bounds.length === 0) {
        let marker = new mapboxgl.Marker({color: "#4668F2"}).setLngLat([lng, lat]).addTo(mapFootfall.current);
      } else {
        let difX = (bounds[1][0] - bounds[0][0]) / 10
        let difY = (bounds[1][1] - bounds[0][1]) / 10

        // minx miny + maxx maxy TO south-western + north-eastern
        mapFootfall.current.fitBounds([
          [bounds[0][0] - difX, bounds[1][1] + difY],
          [bounds[1][0] + difX, bounds[0][1] - difY]
        ])
      }

      mapFootfall.current.addSource(ffLayer, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      mapFootfall.current.addLayer({
        'id': ffLayer+"-line",
        'type': 'line',
        'source': ffLayer,
        'layout': {},
        'paint': {
          'line-color': 'rgba(0,0,0,0.2)',
          'line-width': 0.2
        }
      });


      mapFootfall.current.addLayer({
        'id': ffLayer+"-fill",
        'type': 'fill',
        'source': ffLayer,
        'layout': {},
        'paint': {
          'fill-color': [
            'step',
            ['get', '_score'],
            'gray',
            0.01, '#c6d08a',
            0.1, '#E0FF00',
            0.2, '#FFB500',
            0.3, '#FF8800',
            0.4, '#FF6A00',
            0.5, '#FF4A00',
            0.6, '#FF2500',
            0.7, '#FF0900',
            0.8, '#930410',
            0.9, '#77020b',
          ],
          'fill-opacity': 0.5
        }
      });

      mapFootfall.current.addSource("post", {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      mapFootfall.current.addLayer({
        'id': "post-line",
        'type': 'line',
        'source': "post",
        'layout': {
          visibility: "visible",
        },
        'paint': {
          'line-color': 'rgb(0,13,255)',
          'line-width': 2
        }
      });

      mapFootfall.current.addLayer({
        id: 'post-text',
        type: 'symbol',
        source: "post",
        layout: {
          'text-field': '{sub_postcode}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 18
        }
      });

      let src = mapFootfall.current.getSource(ffLayer);

      if (typeof src !== 'undefined') {
        src.setData(footfallMapData.data);
      }

      if (Object.keys(postcodePolygon).length > 0) {
        src = mapFootfall.current.getSource("post");

        if (typeof src !== 'undefined') {
          src.setData(postcodePolygon.data);
        }
      }

    });
  }

  function initPricingMap(lat, lng, macId, bkId) {
    mapPricing.current = new mapboxgl.Map({
      container: mapPricingContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: 13,
    });

    mapPricing.current.on('load', () => {
      let marker = new mapboxgl.Marker({color: "#4668F2"}).setLngLat([lng, lat]).addTo(mapPricing.current);

      mapPricing.current.loadImage('/icons/pricing-bk.png', (error, image) => {
        if (error) throw error;
        mapPricing.current.addImage('bk', image);
      });

      mapPricing.current.loadImage('/icons/pricing-mac.png', (error, image) => {
        if (error) throw error;
        mapPricing.current.addImage('mac', image);
      });

      mapPricing.current.addSource('pricing-bk', {
        type: 'geojson',
        data: {
          "type": "FeatureCollection",
          "features": []
        },
      });

      mapPricing.current.addLayer({
        id: 'pricing-bk-layer',
        type: 'symbol',
        source: 'pricing-bk',

        layout: {
          'icon-image': 'bk',
          'icon-allow-overlap': true,
        },
      });

      mapPricing.current.addSource('pricing-mac', {
        type: 'geojson',
        data: {
          "type": "FeatureCollection",
          "features": []
        },
      });

      mapPricing.current.addLayer({
        id: 'pricing-mac-layer',
        type: 'symbol',
        source: 'pricing-mac',

        layout: {
          'icon-image': 'mac',
          'icon-allow-overlap': true,
        },
      });

      mapPricing.current.on('click', ['pricing-bk-layer', 'pricing-mac-layer'], (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML("<h3>" + e.features[0].properties.title + "</h3>")
          .addTo(mapPricing.current);
      });

      let jsonDataBk = {
        "type": "FeatureCollection",
        "features": []
      };

      let jsonDataMac = {
        "type": "FeatureCollection",
        "features": []
      };

      if (pricingReport.hasOwnProperty(macId)) {
        for (let i = 0; i < pricingReport[macId].items[0].places.length; i++) {
          jsonDataMac.features.push({
            "type": "Feature",
            "properties": {
              "title": pricingReport[macId].items[0].places[i].name,
            },
            "geometry": {
              "type": "Point",
              "coordinates": [pricingReport[macId].items[0].places[i].longitude, pricingReport[macId].items[0].places[i].latitude]
            }
          });
        }
      }

      if (pricingReport.hasOwnProperty(bkId)) {
        for (let i = 0; i < pricingReport[bkId].items[0].places.length; i++) {
          jsonDataBk.features.push({
            "type": "Feature",
            "properties": {
              "title": pricingReport[bkId].items[0].places[i].name,
            },
            "geometry": {
              "type": "Point",
              "coordinates": [pricingReport[bkId].items[0].places[i].longitude, pricingReport[bkId].items[0].places[i].latitude]
            }
          });
        }
      }

      mapPricing.current.getSource('pricing-bk').setData(jsonDataBk);
      mapPricing.current.getSource('pricing-mac').setData(jsonDataMac);
    })
  }

  function initCompetitorsMap(lat, lng) {
    mapCompetitors.current = new mapboxgl.Map({
      container: mapCompetitorsContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: 15,
    });

    mapCompetitors.current.on('load', () => {
      let marker = new mapboxgl.Marker({color: "#4668F2"}).setLngLat([lng, lat]).addTo(mapCompetitors.current);

      mapCompetitors.current.loadImage('/restaurant-ico30.png', (error, image) => {
        if (error) throw error;
        mapCompetitors.current.addImage('restaurant', image);
      });

      mapCompetitors.current.loadImage('/point-ico30.png', (error, image) => {
        if (error) throw error;
        mapCompetitors.current.addImage('point', image);
      });

      mapCompetitors.current.addSource('tripadvisor', {
        type: 'geojson',
        data: {
          "type": "FeatureCollection",
          "features": []
        },
        cluster: true,
        clusterMaxZoom: 16,
        clusterRadius: 50,
      });

      mapCompetitors.current.addLayer({
        id: 'competitors',
        type: 'symbol',
        source: 'tripadvisor',

        layout: {
          'icon-image': 'point',
          "icon-allow-overlap": true,
        },
      });

      mapCompetitors.current.addLayer({
        id: 'clusters_competitors',
        type: 'circle',
        source: 'tripadvisor',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#0a9532',
            100,
            '#024f18',
            750,
            '#012c0e'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      mapCompetitors.current.addLayer({
        id: 'clusters_competitors_count',
        type: 'symbol',
        source: 'tripadvisor',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      mapCompetitors.current.on('click', ['competitors'], (e) => {
        if (e.features[0].properties.title === undefined) {
          return
        }
        const coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML("<div>Name: " + e.features[0].properties.title + "</div>" + "<div>Rating: " + e.features[0].properties.rating + "</div>" + "<div>Reviews: " + e.features[0].properties.user_ratings_total + "</div>")
          .addTo(mapCompetitors.current);
      });

      let jsonData = {
        "type": "FeatureCollection",
        "features": []
      };

      for (let i = 0; i < competitors.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": competitors[i].name,
            "rating": competitors[i].rating,
            "user_ratings_total": competitors[i].review_count,
            "property_type": 'restaurant'
          },
          "geometry": {
            "type": "Point",
            "coordinates": [competitors[i].lng, competitors[i].lat]
          }
        });
      }

      mapCompetitors.current.getSource('tripadvisor').setData(jsonData);
    })
  }

  useEffect(() => {
    if (reportNum !== null) {
      let tmp = function () {
        let tmp = {}

        tmp[tabSnapshot] = {text: "Snapshot"};
        tmp[tabFootfall] = {text: "Footfall"};
        tmp[tabTraffic] = {text: "Vehicle Traffic"};
        tmp[tabDemographic] = {text: "Demographics"};
        tmp[tabAmenities] = {text: "Amenities"};
        tmp[tabCompetitors] = {text: "Competitors"};

        if (showPano) {
          tmp[tabPanorama] = {text: "Street View"};
        }

        if (pricingReport && (pricingReport.hasOwnProperty(macId) || pricingReport.hasOwnProperty(bkId))) {
          tmp[tabPricing] = {text: "Pricing"};
        }

        tmp[tabDelivery] = {text: "Delivery"};
        tmp[tabRealEstate] = {text: "Real Estate"};

        return tmp;
      }();

      setTabs(tmp);
    }

  }, [pricingReport, reportNum])

  function initAmenitiesMap(lat, lng) {
    mapAmenities.current = new mapboxgl.Map({
      container: mapAmenitiesContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: 15,
    });

    mapAmenities.current.on('load', () => {
      let marker = new mapboxgl.Marker({color: "#4668F2"}).setLngLat([lng, lat]).addTo(mapAmenities.current);

      let amenities = ['atm', 'bank', 'college', 'grocery', 'mall', 'nursery', 'parking', 'postoffice', 'school', 'subway',
        'supermarket', 'trainstation'];

      for (let item of amenities) {
        mapAmenities.current.loadImage('/icons/' + item + '.png', (error, image) => {
          if (error) throw error;
          mapAmenities.current.addImage(item, image);
        });
      }

      mapAmenities.current.addSource('amenities', {
        type: 'geojson',
        data: {
          "type": "FeatureCollection",
          "features": []
        },
      });

      mapAmenities.current.addLayer({
        id: 'amenities-icons',
        type: 'symbol',
        source: 'amenities',
        layout: {
          'icon-image': [
            'coalesce',
            ['image', ['get', 'property_type']],
            ['image', 'point']
          ],
        },
      });

      mapAmenities.current.on('click', ['amenities-icons'], (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML("<h5>" + e.features[0].properties.title + "</h5>" + (e.features[0].properties.cat_name.toLowerCase() === e.features[0].properties.title.toLowerCase() ? "" : "<div>" + e.features[0].properties.cat_name + "</div>"))
          .addTo(mapAmenities.current);
      });

      let jsonData = {
        "type": "FeatureCollection",
        "features": []
      };

      for (let i = 0; i < atm.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {"title": atm[i].name === '' ? 'ATM' : atm[i].name, "property_type": 'atm', "cat_name": "ATM"},
          "geometry": {
            "type": "Point",
            "coordinates": [atm[i].lng, atm[i].lat]
          }
        });
      }

      for (let i = 0; i < banks.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": banks[i].name === '' ? 'Bank' : banks[i].name,
            "property_type": 'bank',
            "cat_name": "Bank"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [banks[i].lng, banks[i].lat]
          }
        });
      }

      for (let i = 0; i < colleges.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": colleges[i].name === '' ? 'College' : colleges[i].name,
            "property_type": 'college',
            "cat_name": "College"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [colleges[i].lng, colleges[i].lat]
          }
        });
      }

      for (let i = 0; i < grocery.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": grocery[i].name === '' ? 'Grocery store' : grocery[i].name,
            "property_type": 'grocery',
            "cat_name": "Grocery store"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [grocery[i].lng, grocery[i].lat]
          }
        });
      }

      for (let i = 0; i < malls.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": malls[i].name === '' ? 'Mall' : malls[i].name,
            "property_type": 'mall',
            "cat_name": "Mall"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [malls[i].lng, malls[i].lat]
          }
        });
      }

      for (let i = 0; i < nurseries.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": nurseries[i].name === '' ? 'Nursery' : nurseries[i].name,
            "property_type": 'nursery',
            "cat_name": "Nursery"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [nurseries[i].lng, nurseries[i].lat]
          }
        });
      }

      for (let i = 0; i < parking.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": parking[i].name === '' ? 'Parking' : parking[i].name,
            "property_type": 'parking',
            "cat_name": "Parking"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [parking[i].lng, parking[i].lat]
          }
        });
      }

      for (let i = 0; i < postOffices.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": postOffices[i].name === '' ? 'Post office' : postOffices[i].name,
            "property_type": 'postoffice',
            "cat_name": "Post office"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [postOffices[i].lng, postOffices[i].lat]
          }
        });
      }

      for (let i = 0; i < schools.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": schools[i].name === '' ? 'School' : schools[i].name,
            "property_type": 'school',
            "cat_name": "School"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [schools[i].lng, schools[i].lat]
          }
        });
      }

      for (let i = 0; i < subway.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": subway[i].name === '' ? 'Subway' : subway[i].name,
            "property_type": 'subway',
            "cat_name": "Subway"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [subway[i].lng, subway[i].lat]
          }
        });
      }

      for (let i = 0; i < supermarkets.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": supermarkets[i].name === '' ? 'Supermarket' : supermarkets[i].name,
            "property_type": 'supermarket',
            "cat_name": "Supermarket"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [supermarkets[i].lng, supermarkets[i].lat]
          }
        });
      }

      for (let i = 0; i < trainstation.length; i++) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "title": trainstation[i].name === '' ? 'Train station' : trainstation[i].name,
            "property_type": 'trainstation',
            "cat_name": "Train station"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [trainstation[i].lng, trainstation[i].lat]
          }
        });
      }

      mapAmenities.current.getSource('amenities').setData(jsonData);
    });
  }

  function GetReports(sess, data, runRec = -1, from_map = false) {

    setLoading(true);

    if (from_map) {
      Track(dispatch, profile, "Report from Map Requested");
    }

    //TODO:: FIX
    if (data.year === 0) {
      data.year = dates[dates.length - 1].year;
      data.month = dates[dates.length - 1].month;
    }

    if (sess && sess !== '') {
      data.sess = sess;
    } else if (reportId && reportId !== '') {
      data.report_id = reportId;

      if (location.hash !== '') {
        switch (location.hash) {
          case '#footfall':
            setTab(tabFootfall);
            break;
          case '#traffic':
            setTab(tabTraffic);
            break;
          case '#demographic':
            setTab(tabDemographic);
            break;
          case '#amenities':
            setTab(tabAmenities);
            break;
          case '#competitors':
            setTab(tabCompetitors);
            break;
        }
      }
    }

    data.competitors_category = competitorsCategory;
    data.geocoder_mode = geocoderMode;

    dispatch(locationReportAction(data)).then((res) => {
      if (res.report_num) {
        if (searchParams.get('sess') !== null && !!searchParams.get('sess')) {
          setReportSess(searchParams.get('sess'));
        }
        window.history.replaceState(null, res.title, '/location-report/' + res.report_num)
        setReportNum(res.report_num);
      }

      if (!!res.address && !!!address) {
        setAddress(res.address);
      }

      if (!!res.lat && !!res.lng) {
        setData({lat: res.lat, lng: res.lng})
      }

      let bkIdTmp = 0;
      let macIdTmp = 0;

      if (!!res.bk_id) {
          bkIdTmp = res.bk_id;
          setBkId(bkIdTmp);
      }

      if (!!res.mac_id) {
          macIdTmp = res.mac_id;
          setMacId(macIdTmp);
      }

      setUseCU(!!res.use_cu);

      let snapshotObj = {
        footfall: {
          avg: null,
          hottestDay: null,
          hottestHour: null,
        },
        traffic: {
          avg: null,
          hottestDay: null,
          hottestHour: null,
        },
        demographics: {
          pop500: null,
          pop3m: null,
          relationship: null,
          ethnicity: null,
          income: null,
        },
        amenities: {
          schools: null,
          supermarkets: null,
          subway: null,
          closest: null,
        },
        competitors: {
          closest: null,
          highestGoogleRating: null,
          highestReviewsNumber: null,
        },
        pricing: {
          macPrice: null,
          macMedian: null,
          bkPrice: null,
          bkMedian: null,
        },
        realEstate: {
          commercialRent: null,
          commercialSale: null,
          residentRent: null,
          residentSale: null,
        },
      };

      if (!!res.total_in_radius) {
        setInRadius({
          'p5000': res.total_in_radius.pop5000,
          'p1000': res.total_in_radius.pop1000,
          'p500': res.total_in_radius.pop500,
          'p100': res.total_in_radius.pop100,
          'b5000': res.total_in_radius.buildings5000,
          'b1000': res.total_in_radius.buildings1000,
          'b500': res.total_in_radius.buildings500,
          'b100': res.total_in_radius.buildings100,
        });

        snapshotObj.demographics.pop500 = !!res.total_in_radius.pop500?res.total_in_radius.pop500:null;
        snapshotObj.demographics.pop3m = !!res.total_in_radius.pop5000?res.total_in_radius.pop5000:null;
      }

      let footfallAvgDayNames = [/*'number of people during the day', */' - Workday, Mon-Fri', ' - Weekend, Sat-Sun'];
      let footfallAvgDayNamesN = [/*'number of vehicles during the day', */' - Workday, Mon-Fri', ' - Weekend, Sat-Sun'];
      let footfallAvgDayByDayNames = ['number of people during a specific day']
      let footfallAvgDayByDayNamesN = ['number of vehicles during a specific day']

      let footfallAvgDay = [];
      let footfallAvgDayN = [];

      let footfallAvgDayByDay_ = [];
      let footfallAvgDayByDayN_ = [];

      /*
      if (!!res.footfall_avg_day_report_all_n?.data?.length > 0) {
        res.footfall_avg_day_report_all_n.data = res.footfall_avg_day_report_all_n.data.sort((a, b) => 1*a.index > 1*b.index ? 1 : -1)
      }*/

      let tmpCalcHottestHourN = function( ) {
        let tmp = {};
        for (let i = 0; i < 24; i++) {
          tmp[i+""] = 0;
        }

        return tmp;
      }();

      if (!!res.footfall_avg_day_report_workday_n?.data?.length > 0) {
        res.footfall_avg_day_report_workday_n.data = res.footfall_avg_day_report_workday_n.data.sort((a, b) => 1 * a.index > 1 * b.index ? 1 : -1)

        for(let itm of res.footfall_avg_day_report_workday_n.data) {
          if (tmpCalcHottestHourN.hasOwnProperty(itm.index)) {
            tmpCalcHottestHourN[itm.index] += itm.value;
          }
        }
      }
      if (!!res.footfall_avg_day_report_weekend_n?.data?.length > 0) {
        res.footfall_avg_day_report_weekend_n.data = res.footfall_avg_day_report_weekend_n.data.sort((a, b) => 1 * a.index > 1 * b.index ? 1 : -1)

        for(let itm of res.footfall_avg_day_report_weekend_n.data) {
          if (tmpCalcHottestHourN.hasOwnProperty(itm.index)) {
            tmpCalcHottestHourN[itm.index] += itm.value;
          }
        }
      }

      let maxHourValueKeyN = Object.keys(tmpCalcHottestHourN).reduce(
        (a, b) => (tmpCalcHottestHourN[a] > tmpCalcHottestHourN[b]?a:b)
      );

      if (tmpCalcHottestHourN[maxHourValueKeyN] > 0) {
        snapshotObj.traffic.hottestHour = parseInt(maxHourValueKeyN)>11?parseInt(maxHourValueKeyN)-12+"PM":maxHourValueKeyN+"AM";
      }

      //footfallAvgDayN.push(!!res.footfall_avg_day_report_all_n?.data?.length > 0?res.footfall_avg_day_report_all_n:[]);
      footfallAvgDayN.push(!!res.footfall_avg_day_report_workday_n?.data?.length > 0 ? res.footfall_avg_day_report_workday_n : []);
      footfallAvgDayN.push(!!res.footfall_avg_day_report_weekend_n?.data?.length > 0 ? res.footfall_avg_day_report_weekend_n : []);

      let hourXAxisTitle = {
        text: 'Hour:',
        offsetX: -560,
        offsetY: 62,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  'bold',
        },
      };

      let avgDayAllNMax = Math.max(
        footfallAvgDayN[0].data.reduce((a, b) => Math.max(a, b.value), 0),
        footfallAvgDayN[1].data.reduce((a, b) => Math.max(a, b.value), 0)
      )

      if (!!res.footfall_avg_day_report_by_day_n?.length>0) {
        for (let i in res.footfall_avg_day_report_by_day_n) {
          res.footfall_avg_day_report_by_day_n[i].data = res.footfall_avg_day_report_by_day_n[i].data.sort((a, b) => 1 * a.index > 1 * b.index ? 1 : -1)

          avgDayAllNMax =  res.footfall_avg_day_report_by_day_n[i].data.reduce((a, b) => Math.max(a, b.value), avgDayAllNMax);

          footfallAvgDayByDayN_.push(!!res.footfall_avg_day_report_by_day_n[i]?.data?.length > 0 ? res.footfall_avg_day_report_by_day_n[i] : []);
        }

        avgDayAllNMax = Math.round(avgDayAllNMax * avgDayMultiplier);

        let tmpN = [];

        for (let i = 0; i < 7; i ++) {
          tmpN.push(
            FormatMultiColumnChartData(
              [footfallAvgDayByDayN_[i]],
              footfallAvgDayByDayNamesN,
              null,
              (a) => Math.round(a),
              hourXAxisTitle,
              null,
              null,
              !!avgDayAllNMax?avgDayAllNMax:null)
          )
        }

        setFootfallAvgDayByDayN([...tmpN]);
      } else {
        avgDayAllNMax = Math.round(avgDayAllNMax * avgDayMultiplier);
      }

      setFootfallAvgDayAllN(
        FormatMultiColumnChartData(
          footfallAvgDayN,
          footfallAvgDayNamesN,
          null,
          (a) => Math.round(a),
          hourXAxisTitle,
          null,
          null,
          !!avgDayAllNMax?avgDayAllNMax:null)
      );

      /*
      if (!!res.footfall_avg_day_report_all_n?.data?.length > 0) {
        setFootfallAvgDayAllN(FormatColumnChartData(res.footfall_avg_day_report_all_n));
      }

      if (!!res.footfall_avg_day_report_workday_n?.data?.length > 0) {
        setFootfallAvgDayWorkdayN(FormatColumnChartData(res.footfall_avg_day_report_workday_n));
      }

      if (!!res.footfall_avg_day_report_weekend_n?.data?.length > 0) {
        setFootfallAvgDayWeekendN(FormatColumnChartData(res.footfall_avg_day_report_weekend_n));
      }*/

      let footfallMonthAvgDayN = null;
      if (!!res.footfall_month_report_n?.data?.length > 0) {
        setFootfallMonthTotalAvgN(res.footfall_month_report_n.avg_per_day);
        footfallMonthAvgDayN = res.footfall_month_report_n.avg_per_day;

        snapshotObj.traffic.avg = footfallMonthAvgDayN;

        let yanno = [
          {
            y: footfallMonthAvgDayN,
            borderColor: '#3935D8',
            label: {
              borderColor: '#3935D8',
              style: {
                color: '#000',
                background: '#c9c8ff',
                "font-size": '18px',
              },
              text: 'day average'
            }
          }
        ];

        let hourXAxisTitle = {
          text: 'Day:',
          offsetX: -560,
          offsetY: 62,
          floating: false,
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
          },
        };

        setAvgWeekendN(res.footfall_month_report_n.avg_weekend);
        setAvgWorkdayN(res.footfall_month_report_n.avg_workday);
        setFootfallMonthN(FormatColumnChartData(res.footfall_month_report_n, null, null, null, yanno, hourXAxisTitle));
      }

      if (!!res.footfall_avg_week_report_n?.data?.length > 0) {

        let yanno = [
          {
            y: footfallMonthAvgDayN,
            borderColor: '#3935D8',
            label: {
              borderColor: '#3935D8',
              style: {
                color: '#000',
                background: '#c9c8ff',
                "font-size": '18px',
              },
              text: 'day average'
            }
          }
        ];

        let hourXAxisTitle = {
          text: 'Day:',
          offsetX: -560,
          offsetY: 62,
          floating: false,
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
          },
        };

        // calc snapshot data
        let maxVal = 0;
        for (let itm of res.footfall_avg_week_report_n.data) {
          if (itm.value > maxVal) {
            maxVal = itm.value;
            snapshotObj.traffic.hottestDay = itm.index;
          }
        }

        setFootfallAvgWeekN(FormatColumnChartData(res.footfall_avg_week_report_n, null, null, null, yanno, hourXAxisTitle));
      }

      /*
      if (!!res.footfall_avg_day_report_all?.data?.length > 0) {
        res.footfall_avg_day_report_all.data = res.footfall_avg_day_report_all.data.sort((a, b) => 1*a.index > 1*b.index ? 1 : -1)
      }*/

      let footfallAvgDayWorkday = null;
      let footfallAvgDayWeekend = null;

      let tmpCalcHottestHour = function( ) {
        let tmp = {};
        for (let i = 0; i < 24; i++) {
          tmp[i+""] = 0;
        }

        return tmp;
      }();

      if (!!res.footfall_avg_day_report_workday?.data?.length > 0) {
        res.footfall_avg_day_report_workday.data = res.footfall_avg_day_report_workday.data.sort((a, b) => 1 * a.index > 1 * b.index ? 1 : -1)

        for (let itm of res.footfall_avg_day_report_workday.data) {
          footfallAvgDayWorkday += itm.value

          if (tmpCalcHottestHour.hasOwnProperty(itm.index)) {
            tmpCalcHottestHour[itm.index] += itm.value;
          }
        }
        footfallAvgDayWorkday = Math.round(footfallAvgDayWorkday/res.footfall_avg_day_report_workday.data.length);
      }
      if (!!res.footfall_avg_day_report_weekend?.data?.length > 0) {
        res.footfall_avg_day_report_weekend.data = res.footfall_avg_day_report_weekend.data.sort((a, b) => 1 * a.index > 1 * b.index ? 1 : -1)

        for (let itm of res.footfall_avg_day_report_weekend.data) {
          footfallAvgDayWeekend += itm.value

          if (tmpCalcHottestHour.hasOwnProperty(itm.index)) {
            tmpCalcHottestHour[itm.index] += itm.value;
          }
        }
        footfallAvgDayWeekend = Math.round(footfallAvgDayWeekend/res.footfall_avg_day_report_weekend.data.length);
      }

      let maxHourValueKey = Object.keys(tmpCalcHottestHour).reduce(
        (a, b) => (tmpCalcHottestHour[a] > tmpCalcHottestHour[b]?a:b)
      );

      if (tmpCalcHottestHour[maxHourValueKey] > 0) {
        snapshotObj.footfall.hottestHour = parseInt(maxHourValueKey)>11?parseInt(maxHourValueKey)-12+"PM":maxHourValueKey+"AM";
      }

      //footfallAvgDay.push(!!res.footfall_avg_day_report_all?.data?.length > 0?res.footfall_avg_day_report_all:[]);
      footfallAvgDay.push(!!res.footfall_avg_day_report_workday?.data?.length > 0 ? res.footfall_avg_day_report_workday : []);
      footfallAvgDay.push(!!res.footfall_avg_day_report_weekend?.data?.length > 0 ? res.footfall_avg_day_report_weekend : []);

      let yannoAvgDayWorkdayNoOffset = {
        y: footfallAvgDayWorkday,
        borderColor: '#3935D8',
        label: {
          borderColor: '#3935D8',
          style: {
            color: '#000',
            background: '#c9c8ff',
            "font-size": '18px',
          },
          text: 'Workday hour average'
        }
      };

      let yannoAvgDayWorkday = {
        y: footfallAvgDayWorkday,
        borderColor: '#3935D8',
        label: {
          offsetX: -150,
          borderColor: '#3935D8',
          style: {
            color: '#000',
            background: '#c9c8ff',
            "font-size": '18px',
          },
          text: 'Workday hour average'
        }
      };

      let yannoAvgDayWeekend = {
        y: footfallAvgDayWeekend,
        borderColor: '#3935D8',
        label: {
          borderColor: '#3935D8',
          style: {
            color: '#000',
            background: '#c9c8ff',
            "font-size": '18px',
          },
          text: 'Weekend hour average'
        }
      };

      let yannoAvgDay = [
        yannoAvgDayWorkday,
        yannoAvgDayWeekend
      ];

      let avgDayAllMax = Math.max(
        footfallAvgDay[0].data.reduce((a, b) => Math.max(a, b.value), 0),
        footfallAvgDay[1].data.reduce((a, b) => Math.max(a, b.value), 0)
      );

      if (!!res.footfall_avg_day_report_by_day?.length>0) {

        for (let i in res.footfall_avg_day_report_by_day) {
          res.footfall_avg_day_report_by_day[i].data = res.footfall_avg_day_report_by_day[i].data.sort((a, b) => 1 * a.index > 1 * b.index ? 1 : -1)

          avgDayAllMax = res.footfall_avg_day_report_by_day[i].data.reduce((a, b) => Math.max(a, b.value), avgDayAllMax)

          footfallAvgDayByDay_.push(!!res.footfall_avg_day_report_by_day[i]?.data?.length > 0 ? res.footfall_avg_day_report_by_day[i] : []);
        }

        avgDayAllMax = Math.round(avgDayAllMax * avgDayMultiplier);

        let tmp = [];

        for (let i = 0; i < 7; i ++) {
          tmp.push(
            FormatMultiColumnChartData(
              [footfallAvgDayByDay_[i]],
              footfallAvgDayByDayNames,
              null,
              (a) => Math.round(a) ,
              hourXAxisTitle,
              null,
              (i <= 4?[yannoAvgDayWorkdayNoOffset]:[yannoAvgDayWeekend]),
              !!avgDayAllMax?avgDayAllMax:null
            )
          )
        }

        setFootfallAvgDayByDay([...tmp]);
      } else {
        avgDayAllMax = Math.round(avgDayAllMax * avgDayMultiplier);
      }

      setFootfallAvgDayAll(FormatMultiColumnChartData(footfallAvgDay, footfallAvgDayNames, null, (a) => Math.round(a) , hourXAxisTitle, null, yannoAvgDay, !!avgDayAllMax?avgDayAllMax:null));

      /*
      if (!!res.footfall_avg_day_report_weekend?.data?.length > 0) {
        setFootfallAvgDayWeekend(FormatColumnChartData(res.footfall_avg_day_report_weekend));
      }

      if (!!res.footfall_avg_day_report_workday?.data?.length > 0) {
        setFootfallAvgDayWorkday(FormatColumnChartData(res.footfall_avg_day_report_workday));
      }

      if (!!res.footfall_avg_day_report_all?.data?.length > 0) {
        setFootfallAvgDayAll(FormatColumnChartData(res.footfall_avg_day_report_workday));
      }*/

      let footfallMonthDayAvg = null;
      if (!!res.footfall_month_report?.data?.length > 0) {
        setFootfallMonthTotalAvg(res.footfall_month_report.avg_per_day);
        setAvgWeekend(res.footfall_month_report.avg_weekend);
        setAvgWorkday(res.footfall_month_report.avg_workday);
        footfallMonthDayAvg = res.footfall_month_report.avg_per_day;

        snapshotObj.footfall.avg = footfallMonthDayAvg;

        let yanno = [
            {
              y: res.footfall_month_report.avg_per_day,
              borderColor: '#3935D8',
              label: {
                borderColor: '#3935D8',
                style: {
                  color: '#000',
                  background: '#c9c8ff',
                  "font-size": '18px',
                },
                text: 'day average'
              }
            }
          ];

        let hourXAxisTitle = {
          text: 'Day:',
          offsetX: -560,
          offsetY: 62,
          floating: false,
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
          },
        };

        setFootfallMonth(FormatColumnChartData(res.footfall_month_report, null, null, null, yanno, hourXAxisTitle));
      }

      if (!!res.footfall_avg_week_report?.data?.length > 0) {

        let yanno = [
          {
            y: footfallMonthDayAvg,
            borderColor: '#3935D8',
            label: {
              borderColor: '#3935D8',
              style: {
                color: '#000',
                background: '#c9c8ff',
                "font-size": '18px',
              },
              text: 'day average'
            }
          }
        ];

        let hourXAxisTitle = {
          text: 'Day:',
          offsetX: -560,
          offsetY: 62,
          floating: false,
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
          },
        };

        // calc snapshot data
        let maxVal = 0;
        for (let itm of res.footfall_avg_week_report.data) {
          if (itm.value > maxVal) {
            maxVal = itm.value;
            snapshotObj.footfall.hottestDay = itm.index;
          }
        }

        setFootfallAvgWeek(FormatColumnChartData(res.footfall_avg_week_report, null, null, null, yanno, hourXAxisTitle));
      }

      /*
      if (!!res.total_in_radius) {
        setTotalInRadius(res.total_in_radius.value)
      }*/

      if (!!res.location_reports && !!res.location_reports.ethnic_group_report?.data?.length > 0) {
        setEthnicReport(res.location_reports.ethnic_group_report.data);
        let ethnicTotal = 0;

        let maxEthnic = {v: 0, k: ""};

        for (let item of res.location_reports.ethnic_group_report.data) {
          ethnicTotal += item.value;

          if (maxEthnic.v < item.value) {
            maxEthnic.v = item.value;
            maxEthnic.k = item.index;
          }
        }

        if (maxEthnic.v > 0) {
          snapshotObj.demographics.ethnicity = maxEthnic.k + " - " + Math.round(maxEthnic.v * 100 / ethnicTotal) + "%";
        }

        setEthnicTotal(ethnicTotal)
      }

      if (!!res.location_reports && !!res.location_reports.religion_report?.data?.length > 0) {
        setReligionReport(res.location_reports.religion_report.data);
        let religionTotal = 0;
        for (let item of res.location_reports.religion_report.data) {
          religionTotal += item.value;
        }
        setReligionTotal(religionTotal)
      }

      if (!!res.location_reports && !!res.location_reports.age_report?.data?.length > 0) {
        let ageTotal = 0;
        for (let item of res.location_reports.age_report.data) {
          ageTotal += item.value
        }
        if (ageTotal > 0) {
          for (let i = 0; i < res.location_reports.age_report.data.length; i++) {
            res.location_reports.age_report.data[i].value = Math.round(res.location_reports.age_report.data[i].value * 100 / ageTotal)
          }
          setAgeReport(FormatColumnChartData(res.location_reports.age_report, null, function (value) {
            return value + "%";
          }))
        } else {
          setAgeReport(FormatColumnChartData(res.location_reports.age_report));
        }
      }

      if (!!res.location_reports && !!res.location_reports.gender_report?.data?.length > 0) {
        //setGenderReport(FormatPieChartData(res.location_reports.gender_report.data))
        let f, m = 0;
        for (let item of res.location_reports.gender_report.data) {
          if (item.index === 'Male') {
            m = item.value;
          } else if (item.index === 'Female') {
            f = item.value;
          }
        }
        if (m !== 0 && f !== 0) {
          m = Math.round(m / (m + f) * 100)
          setGenderMale(m);
          setGenderFemale(100 - m);
        }
      }

      if (!!res.location_reports && !!res.location_reports.relationship_status_report?.data?.length > 0) {
        let relationshipTotal = 0;
        for (let item of res.location_reports.relationship_status_report.data) {
          relationshipTotal += item.value;
        }

        let relMax = 0;
        if (relationshipTotal > 0) {
          for (let i = 0; i < res.location_reports.relationship_status_report.data.length; i++) {
            res.location_reports.relationship_status_report.data[i].value = Math.round(res.location_reports.relationship_status_report.data[i].value * 100 * 100 / relationshipTotal) / 100

            if (relMax < res.location_reports.relationship_status_report.data[i].value) {
              relMax = res.location_reports.relationship_status_report.data[i].value;

              snapshotObj.demographics.relationship = res.location_reports.relationship_status_report.data[i].index + " - " + res.location_reports.relationship_status_report.data[i].value +"%";
            }
          }
        }
        setRelationshipReport(FormatDonutChartData(res.location_reports.relationship_status_report.data))
      }

      if (!!res.city_report && !!res.city_report.median_income_rate) {
        setMedianIncomeRate(res.city_report.median_income_rate);

        let rate = res.city_report.median_income_rate.split('/')[0] * 1;
        setAreaWealth(rate < 4 ? 'Poor area' : (rate < 8 ? 'Average area' : 'Wealthy area'))
      }

      if (!!res.city_report && !!res.city_report.median_income) {

        snapshotObj.demographics.income = res.city_report.median_income;

        setMedianIncome(res.city_report.median_income);
      }

      if (!!res.city_report && !!res.city_report.crime_rate_rating) {
        setCrimeRateRating(res.city_report.crime_rate_rating);
      }

      if (!!res.city_report && !!res.city_report.crime_types) {
        setCrimeTypes(JSON.parse(res.city_report.crime_types));
      }

      if (!!res.closest_competitors_report && res.closest_competitors_report?.data?.length > 0) {

        let snapshotTmp = {
          closest: null,
          rating: null,
          reviews: null,
        };

        let priceLevel = 0;
        let priceCnt = 0;
        for (let i = 0; i < res.closest_competitors_report.data.length; i++) {
          res.closest_competitors_report.data[i].type = JSON.parse(res.closest_competitors_report.data[i].type);

          if (snapshotTmp.closest === null || snapshotTmp.closest > res.closest_competitors_report.data[i].distance) {
            snapshotObj.competitors.closest = res.closest_competitors_report.data[i].name;
            snapshotTmp.closest = res.closest_competitors_report.data[i].distance;
          }

          if (snapshotTmp.rating === null || snapshotTmp.rating < res.closest_competitors_report.data[i].rating) {
            snapshotObj.competitors.highestGoogleRating = res.closest_competitors_report.data[i].name;
            snapshotTmp.rating = res.closest_competitors_report.data[i].distance;
          }

          if (snapshotTmp.reviews === null || snapshotTmp.reviews < res.closest_competitors_report.data[i].review_count) {
            snapshotObj.competitors.highestReviewsNumber = res.closest_competitors_report.data[i].name;
            snapshotTmp.reviews = res.closest_competitors_report.data[i].review_count;
          }

          if (res.closest_competitors_report.data[i].price_level > -1) {
            priceLevel += res.closest_competitors_report.data[i].price_level;
            priceCnt += 1;
          }
          let index = res.closest_competitors_report.data[i].type.indexOf("food");
          if (index > -1) {
            res.closest_competitors_report.data[i].type.splice(index, 1);
          }

          index = res.closest_competitors_report.data[i].type.indexOf("point_of_interest");
          if (index > -1) {
            res.closest_competitors_report.data[i].type.splice(index, 1);
          }

          index = res.closest_competitors_report.data[i].type.indexOf("establishment");
          if (index > -1) {
            res.closest_competitors_report.data[i].type.splice(index, 1);
          }
        }

        setCompetitors(res.closest_competitors_report.data);

        if (priceCnt > 0) {
          setPriceLevel(Math.round(priceLevel / priceCnt) + "/5");
        }
      }

      let amenitiesNearest = {'d': 0, 'n': ""}
      if (!!res.amenities_report && res.amenities_report?.schools?.data?.length > 0) {
        setSchools(res.amenities_report.schools.data);
        setSchoolsCnt(res.amenities_report.schools.count);

        let scnt = 0;
        for (let itm of res.amenities_report.schools.data) {
          if (itm.distance <= 1760) {
            scnt += 1
          } else {
            break
          }
        }

        if (scnt > 0) {
          snapshotObj.amenities.schools = scnt;
        }

        if ((amenitiesNearest.n === "" || amenitiesNearest.d > res.amenities_report.schools.data[0].distance) && res.amenities_report.schools.data[0].name !== "") {
          amenitiesNearest.d = res.amenities_report.schools.data[0].distance;
          amenitiesNearest.n = res.amenities_report.schools.data[0].name;
        }
      }

      if (!!res.amenities_report && res.amenities_report?.colleges?.data?.length > 0) {
        setColleges(res.amenities_report.colleges.data);
        setCollegesCnt(res.amenities_report.colleges.count);

        if ((amenitiesNearest.n === "" || amenitiesNearest.d > res.amenities_report.colleges.data[0].distance) && res.amenities_report.colleges.data[0].name !== "") {
          amenitiesNearest.d = res.amenities_report.colleges.data[0].distance;
          amenitiesNearest.n = res.amenities_report.colleges.data[0].name;
        }
      }

      if (!!res.amenities_report && res.amenities_report?.nurseries?.data?.length > 0) {
        setNurseries(res.amenities_report.nurseries.data);
        setNurseriesCnt(res.amenities_report.nurseries.count);

        if ((amenitiesNearest.n === "" || amenitiesNearest.d > res.amenities_report.nurseries.data[0].distance) && res.amenities_report.nurseries.data[0].name !== "") {
          amenitiesNearest.d = res.amenities_report.nurseries.data[0].distance;
          amenitiesNearest.n = res.amenities_report.nurseries.data[0].name;
        }
      }

      if (!!res.amenities_report && res.amenities_report?.post_offices?.data?.length > 0) {
        setPostOffices(res.amenities_report.post_offices.data);
        setPostOfficesCnt(res.amenities_report.post_offices.count);

        if ((amenitiesNearest.n === "" || amenitiesNearest.d > res.amenities_report.post_offices.data[0].distance) && res.amenities_report.post_offices.data[0].name !== "") {
          amenitiesNearest.d = res.amenities_report.post_offices.data[0].distance;
          amenitiesNearest.n = res.amenities_report.post_offices.data[0].name;
        }
      }

      if (!!res.amenities_report && res.amenities_report?.malls?.data?.length > 0) {
        setMalls(res.amenities_report.malls.data);
        setMallsCnt(res.amenities_report.malls.count);

        if ((amenitiesNearest.n === "" || amenitiesNearest.d > res.amenities_report.malls.data[0].distance) && res.amenities_report.malls.data[0].name !== "") {
          amenitiesNearest.d = res.amenities_report.malls.data[0].distance;
          amenitiesNearest.n = res.amenities_report.malls.data[0].name;
        }
      }

      if (!!res.amenities_report && res.amenities_report?.banks?.data?.length > 0) {
        setBanks(res.amenities_report.banks.data);
        setBanksCnt(res.amenities_report.banks.count);

        if ((amenitiesNearest.n === "" || amenitiesNearest.d > res.amenities_report.banks.data[0].distance) && res.amenities_report.banks.data[0].name !== "") {
          amenitiesNearest.d = res.amenities_report.banks.data[0].distance;
          amenitiesNearest.n = res.amenities_report.banks.data[0].name;
        }
      }

      if (!!res.amenities_report && res.amenities_report?.atm?.data?.length > 0) {
        setAtm(res.amenities_report.atm.data);
        setAtmCnt(res.amenities_report.atm.count);
      }

      if (!!res.amenities_report && res.amenities_report?.grocery?.data?.length > 0) {
        setGrocery(res.amenities_report.grocery.data);
        setGroceryCnt(res.amenities_report.grocery.count);

        if ((amenitiesNearest.n === "" || amenitiesNearest.d > res.amenities_report.grocery.data[0].distance) && res.amenities_report.grocery.data[0].name !== "") {
          amenitiesNearest.d = res.amenities_report.grocery.data[0].distance;
          amenitiesNearest.n = res.amenities_report.grocery.data[0].name;
        }
      }

      if (!!res.amenities_report && res.amenities_report?.supermarkets?.data?.length > 0) {
        setSupermarkets(res.amenities_report.supermarkets.data);
        setSupermarketsCnt(res.amenities_report.supermarkets.count);

        let scnt = 0;
        for (let itm of res.amenities_report.supermarkets.data) {
          if (itm.distance <= 1760) {
            scnt += 1
          } else {
            break
          }
        }

        if (scnt > 0) {
          snapshotObj.amenities.supermarkets = scnt;
        }

        if ((amenitiesNearest.n === "" || amenitiesNearest.d > res.amenities_report.supermarkets.data[0].distance) && res.amenities_report.supermarkets.data[0].name !== "") {
          amenitiesNearest.d = res.amenities_report.supermarkets.data[0].distance;
          amenitiesNearest.n = res.amenities_report.supermarkets.data[0].name;
        }
      }

      if (!!res.d_geo) {
        setDGeo(res.d_geo);
      }

      /*
      if (!!res.amenities_report && res.amenities_report?.nchgp?.data?.length > 0) {
        setDoctors(res.amenities_report.nchgp.data);
        setDoctorsCnt(res.amenities_report.nchgp.count);
      }*/

      /*
      if (!!res.amenities_report && res.amenities_report?.pharmacies?.data?.length > 0) {
        setPharm(res.amenities_report.pharmacies.data);
        setPharmCnt(res.amenities_report.pharmacies.count);
      }*/

      if (!!res.amenities_report && res.amenities_report?.business_centers?.data?.length > 0) {
        //setBusinessCenters(res.amenities_report.business_centers.data);
        //setBusinessCentersCnt(res.amenities_report.business_centers.count);

        if ((amenitiesNearest.n === "" || amenitiesNearest.d > res.amenities_report.business_centers.data[0].distance) && res.amenities_report.business_centers.data[0].name !== "") {
          amenitiesNearest.d = res.amenities_report.business_centers.data[0].distance;
          amenitiesNearest.n = res.amenities_report.business_centers.data[0].name;
        }
      }

      if (!!res.transport_report && res.transport_report?.bicycle_parking?.data?.length > 0) {
        //setBicycle(res.transport_report.bicycle_parking.data);
        //setBicycleCnt(res.transport_report.bicycle_parking.count);
      }

      if (!!res.transport_report && res.transport_report?.motorcycle_parking?.data?.length > 0) {
        //setMotorcycle(res.transport_report.motorcycle_parking.data);
        //setMotorcycleCnt(res.transport_report.motorcycle_parking.count);
      }

      if (!!res.transport_report && res.transport_report?.parking?.data?.length > 0) {
        setParking(res.transport_report.parking.data);
        setParkingCnt(res.transport_report.parking.count);
      }

      if (!!res.transport_report && res.transport_report?.train_station?.data?.length > 0) {
        setTrainstation(res.transport_report.train_station.data);
        setTrainstationCnt(res.transport_report.train_station.count);
      }

      if (!!res.transport_report && res.transport_report?.subway.data?.length > 0) {
        setSubway(res.transport_report.subway.data);
        setSubwayCnt(res.transport_report.subway.count);

        let scnt = 0;
        for (let itm of res.transport_report.subway.data) {
          if (itm.distance <= 1760) {
            scnt += 1
          } else {
            break
          }
        }

        if (scnt > 0) {
          snapshotObj.amenities.subway = scnt;
        }
      }

      //SNAPSHOT amenitiesNearest
      if (amenitiesNearest.d > 0 && amenitiesNearest.n !== "") {
        snapshotObj.amenities.closest = amenitiesNearest.n + " - " + amenitiesNearest.d + " yards"
      }


      if (!!res.display_mode && displayMode !== res.display_mode) {
        setDisplayMode(res.display_mode);

        snapshotObj.footfall = null;
        snapshotObj.traffic = null;

        //setTab(res.display_mode==="footfall"?tabFootfall:tabDemographic);
      }

      if (!!res.place_name && res.place_name !== "") {
        setPlaceName(res.place_name);
      }

      if (!!res.footfall_visitation_avg_report) {
        let workdayData = res.footfall_visitation_avg_report.workday.transient + res.footfall_visitation_avg_report.workday.residents + res.footfall_visitation_avg_report.workday.workers;
        let weekendData = res.footfall_visitation_avg_report.weekend.transient + res.footfall_visitation_avg_report.weekend.residents + res.footfall_visitation_avg_report.weekend.workers;
        let dayData = res.footfall_visitation_avg_report.day.transient + res.footfall_visitation_avg_report.day.residents + res.footfall_visitation_avg_report.day.workers;

        let tmpData = {
          WorkdayT: Math.round(res.footfall_visitation_avg_report.workday.transient / (workdayData ? workdayData : 1) * 100),
          WeekendT: Math.round(res.footfall_visitation_avg_report.weekend.transient / (weekendData ? weekendData : 1) * 100),
          DayT: Math.round(res.footfall_visitation_avg_report.day.transient / (dayData ? dayData : 1) * 100),
          WorkdayR: Math.round(res.footfall_visitation_avg_report.workday.residents / (workdayData ? workdayData : 1) * 100),
          WeekendR: Math.round(res.footfall_visitation_avg_report.weekend.residents / (weekendData ? weekendData : 1) * 100),
          DayR: Math.round(res.footfall_visitation_avg_report.day.residents / (dayData ? dayData : 1) * 100),
          WorkdayW: Math.round(res.footfall_visitation_avg_report.workday.workers / (workdayData ? workdayData : 1) * 100),
          WeekendW: Math.round(res.footfall_visitation_avg_report.weekend.workers / (weekendData ? weekendData : 1) * 100),
          DayW: Math.round(res.footfall_visitation_avg_report.day.workers / (dayData ? dayData : 1) * 100),
        };

        setFootfallVisitationAvg({...tmpData});
      }

      if (!!res.footfall_map_data && res.footfall_map_data.length > 0) {
        let jsonData = {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": []
          }
        };

        for (let h of res.footfall_map_data) {
          jsonData.data.features.push({
            "type": "Feature",
            "properties": {"_score": h.score},
            "geometry": {
              "type": "Polygon",
              "coordinates": [cellToBoundary(h.code, true)]
            }
          });
        }

        setFootfallMapData({...jsonData});
      }

      if (res?.geocoder_mode === 1) {
        setGeocoderMode(1);

        if (res?.postcode_data !== undefined) {
          let jsonData = {
            "type": "geojson",
            "data": {
              "type": "FeatureCollection",
              "features": []
            }
          };
          let h = JSON.parse(res.postcode_data.geom);

          jsonData.data.features.push({
            "type": "Feature",
            "properties": {"sub_postcode": res.postcode_data.sub_postcode},
            "geometry": {
              "type": h.type,
              "coordinates": h.coordinates
            }
          });

          setPostcodePolygon({...jsonData});
          setSubPostcode(res.postcode_data.sub_postcode);
        }

        if(!!res?.bounds) {
          setBounds(res.bounds);
        }


      }

      if (!!res.timings && res?.timings.length > 0) {
        setTimings(res.timings);
      }

      if (profile?.role_id === ROLE_USER) {
        profile.free_service_use = res?.free_service_use?res.free_service_use:0;
        setFreeUses(profile.free_service_use);
        storeUser(profile);
      }

      if (res?.geocoder_mode !== undefined) {
        setGeocoderMode(res.geocoder_mode);
      }

      if (res?.pricing_report && res?.pricing_menu) {

        if (!!macIdTmp && res.pricing_report.hasOwnProperty(macIdTmp) && res.pricing_report[macIdTmp].items.length > 0 && res.pricing_report[macIdTmp].items[0].places.length > 0) {
          snapshotObj.pricing.macMedian = res.pricing_report[macIdTmp].items[0].median;

          let tmpDist = null;
          for (let itm of res.pricing_report[macIdTmp].items[0].places) {
            if (tmpDist === null || tmpDist > itm.distance) {
              tmpDist = itm.distance;

              snapshotObj.pricing.macPrice = itm.price_value;
            }
          }
        }

        if (!!bkIdTmp && res.pricing_report.hasOwnProperty(bkIdTmp) && res.pricing_report[bkIdTmp].items.length > 0 && res.pricing_report[bkIdTmp].items[0].places.length > 0) {
          snapshotObj.pricing.bkMedian = res.pricing_report[bkIdTmp].items[0].median;

          let tmpDist = null;
          for (let itm of res.pricing_report[bkIdTmp].items[0].places) {
            if (tmpDist === null || tmpDist > itm.distance) {
              tmpDist = itm.distance;

              snapshotObj.pricing.bkPrice = itm.price_value;
            }
          }
        }

        setPricingReport(res.pricing_report);
        setPricingMenuItems(res.pricing_menu);
      }

      // get products for brands
      /*
      if (res && res.brands && res.brands.length > 0) {
        setBrandPoints(res.brands);
        // get brands ids
        let brandIds = res.brands.map((item) => {
          return item.menu_id;
        });

        //TODO:: add multiloading state, replace this
        dispatch(getPointsProductsAction({brands: brandIds})).then((res) => {
          if (res && res.products) {
            setBrandItems(res.products);
          }
        }).catch(err => {
          toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
        });
      }*/

      if (res && res?.real_estate_report) {
        setRealEstateReport({...res.real_estate_report});

        if (res.real_estate_report.rent_commercial_median_5_m > 0) {
          snapshotObj.realEstate.commercialRent = res.real_estate_report.rent_commercial_median_5_m;
        }

        if (res.real_estate_report.sale_commercial_median_5_m > 0) {
          snapshotObj.realEstate.commercialSale = res.real_estate_report.sale_commercial_median_5_m;
        }

        if (res.real_estate_report.rent_resident_median_5_m > 0) {
          snapshotObj.realEstate.residentRent = res.real_estate_report.rent_resident_median_5_m;
        }

        if (res.real_estate_report.sale_resident_median_5_m > 0) {
          snapshotObj.realEstate.residentSale = res.real_estate_report.sale_resident_median_5_m;
        }
      }

      if (res && res?.location) {
        setRealEstateLocation(res.location);
      }

      if (res && res?.avg_footfall) {
        setAvgFootfall(FormatColumnChartData(res.avg_footfall, (a) => BeautifyAvgFootfallDate(a), (a) => Math.round(a)))
      }

      if (res && res?.competitors_types) {
        setCuTypes(res.competitors_types);
        let tmpTypes = [];
        if (res.competitors_types.indexOf("pizza") >= 0) {
          tmpTypes.push("pizza");
        }
        if (res.competitors_types.indexOf("burgers") >= 0) {
          tmpTypes.push("burgers");
        }
        HandleCuFilter("types", tmpTypes);
      }

      if (!!res.oc21) {
        setOc21(res.oc21);
      }

      setSnapshot(snapshotObj);

      setReady(true);
      setLoading(false);

    }).catch((err) => {
      setLoading(false);
      console.log(err)
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  useEffect(() => {
    setShowPromo(/*!isAuth && !advCookie*/ false);
  }, [isAuth, advCookie]);

  useEffect(() => {
    setShowPromo2(advCookie2!==true);
  }, [advCookie2]);

  useEffect(() => {
    const dFilter = {page: 1, limit: 10, sort: 'created', sortd: 'desc'};
    let curFilter = localStorage.getItem(filterName);
    curFilter = curFilter ? JSON.parse(curFilter) : dFilter;

    setMyReportsFilter(curFilter);
  }, []);

  useEffect(() => {

    if (!!isAuth && myReportsFilter !== null && !reportId && !requestParams.get('hex')) {

      setLoading(true);
      dispatch(myReportsAction(myReportsFilter)).then((data) => {
        setMyReports(data.items || []);
        setMyReportsTotal(data.total);
        localStorage.setItem(filterName, JSON.stringify(myReportsFilter));

        setLoading(false);
      }).catch(err => {
        console.log(err)
        setLoading(false);
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [isAuth, myReportsFilter]);

  useEffect(() => {

    let auth = false;
    if ([ROLE_ADMIN, ROLE_MANAGER, ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_REPORTS].indexOf(profile?.role_id) >= 0) {
      auth = true
    }

    if ([ROLE_USER].indexOf(profile?.role_id) >= 0) {
      auth = true
      setFreeUses(profile?.free_service_use?profile.free_service_use:0);
    }

    setIsAuth(auth);

    if (searchParams.get('sess') !== null && searchParams.get('sess') !== '') {
      GetReports(searchParams.get('sess'), data);
    } else if (!!reportId && reportId !== 'xxx') {
      GetReports('', data);
    } else if (!!!initGeocoder && !!!ready && auth ) {
      // check auth

      if (!!document.getElementById("geocoder-container")) {
        document.getElementById("geocoder-container").innerHTML = "";

        geocoder.current = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          bbox: gbBbox,
          mapboxgl: mapboxgl,
          placeholder: 'Search Location',
          reverseGeocode: true,
          language: "en-GB",
          types: "address",
        });

        geocoder.current.addTo('#geocoder-container');

        geocoder.current.on('result', (e) => {

          // remove `click` marker if exists
          //processNearbyPoints(e.result.center[0], e.result.center[1]);
          let _data = {
            postcode: '',
            hex: '',
            lat: 0,
            lng: 0,
            year: 0,
            month: 0,
            address: '',
          };

          for (let item of e.result.context) {
            if (item.id.includes('postcode')) {
              _data.postcode = item.text;
              break;
            }
          }

          if (_data.postcode === '' && e.result.id.includes('postcode')) {
            _data.postcode = e.result.text;
          }

          if (!!requestParams.get('hex') && requestParams.get('hex') !== "" && cellToLatLng(requestParams.get('hex')).length >= 2) {
            let tmpLatLng = cellToLatLng(requestParams.get('hex'));
            _data.lat = tmpLatLng[0];
            _data.lng = tmpLatLng[1];
            _data.hex = requestParams.get('hex');
          } else {
            _data.lat = e.result.center[1];
            _data.lng = e.result.center[0];
            _data.hex = latLngToCell(_data.lat, _data.lng, 10);
          }

          _data.year = dates[activeDate].year;
          _data.month = dates[activeDate].month;
          _data.address = e.result.place_name;

          setData({..._data});
          setAddress(e.result.place_name);
        });

        setInitGeocoder(true);
      }

      let hex = requestParams.get('hex');
      if (!!hex) {
        let latLng = cellToLatLng(hex);
        if (gbBbox[0] < latLng[1] && latLng[1] < gbBbox[2] && gbBbox[1] < latLng[0] && latLng[0] < gbBbox[3]) {
          geocoder.current.query(latLng[0] + "," + latLng[1]);
        }
      }
    }
  }, [initGeocoder])

  function ClearGeocoder() {
    let _data = {
      postcode: '',
      hex: '',
      lat: 0,
      lng: 0,
      year: 0,
      month: 0,
      address: '',
    };

    setData({..._data});
    setAddress(null);
  }

  function BeautifyAvgFootfallDate(dt) {
    if (dt.indexOf("-") < 0) {
      return dt
    }

    let dtArr = dt.split("-")

    return monthsShort.hasOwnProperty(dtArr[1])?dtArr[0]+" "+monthsShort[dtArr[1]]:dt;
  }

  function FormatDonutChartData(reportData) {
    let xData = [];
    let yData = [];

    for (let item of reportData) {
      if (item.index === 'Total') {
        continue;
      }
      if (item.value > 0) {
        xData.push(item.index);
        yData.push(item.value);
      }
    }

    return {
      series: yData,
      options: {
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          }
        },
        labels: xData,
        colors: ['#2C2C2C', '#3935D8', '#8A95A5', '#E69C37', '#DF6C92', '#52B3C8', '#D3E9D7'],
        legend: {
          position: 'left',
          showForZeroSeries: true,
        },
      }
    };
  }

  /*
  function FormatPieChartData(reportData) {

    let xData = [];
    let yData = [];

    for (let item of reportData) {
      if (item.index === 'Total') {
        continue;
      }
      xData.push(item.index);
      yData.push(item.value);
    }

    return {
      series: yData,
      options: {
        chart: {
          width: 200,
          type: 'pie',
        },
        labels: xData,
        colors: ['#3935D8', '#2C2C2C', '#8A95A5'],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: 60,
              minAngleToShowLabel: 1,
            }
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#111']
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            borderWidth: 0
          },
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed(1) + '%']
          }
        },

        legend: {
          show: false
        }
      },
    };
  }
  */
  function FormatColumnChartData(reportData, formatterX, formatterY, annotationsX, annotationsY, xAxisTitle) {

    let xData = [];
    let yData = [];

    if (!!reportData.data) {
      for (let item of reportData.data) {
        xData.push(item.index);
        yData.push(item.value);
      }
    }

    return {
      series: [{name: reportData.fields[1],data: yData}],
      options: {
        annotations: {
          yaxis: !!annotationsY?annotationsY:[],
          xaxis: !!annotationsX?annotationsX:[],
        },
        chart: {
          height: 200,
          type: 'bar',
        },
        colors: ['#3935D8'],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        stroke: {
          width: 1.5,
          dashArray: 0,
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: xData,
          title: !!xAxisTitle?xAxisTitle:{},
          labels: {
            style: {
              fontSize: '10px'
            },
            formatter: !!!formatterX ? (value) => {
              return value;
            } : formatterX,
          },
        },
        yaxis: {
          title: {
            text: reportData.fields[1]
          },
          labels: {
            formatter: !!!formatterY ? (value) => {
              return value;
            } : formatterY,
          }
        },
      }
    };
  }

  function FormatMultiColumnChartData(reportData, reportNames, formatterX, formatterY, xAxisTitle, annotationsX, annotationsY, max) {

    let xData = [];
    let items = [];

    let yAxisLabel = '';

    for (let i = 0; i < reportData.length; i++) {
      let yData = [];
      if (reportData[i]?.data) {
        yAxisLabel = reportData[i].fields[1];

        for (let item of reportData[i].data) {
          yData.push(item.value);
          if (i === 0) {
            xData.push(item.index);
          }
        }
      }
      items.push({name: reportNames[i], data: yData});
    }

    return {
      series: items,
      options: {
        annotations: {
          yaxis: !!annotationsY?annotationsY:[],
          xaxis: !!annotationsX?annotationsX:[],
        },
        chart: {
          height: 400,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '45%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true
        },
        colors: ['#3935D8', '#FFC81D'],//'#8A95A5',
        xaxis: {
          categories: xData,
          title: !!xAxisTitle?xAxisTitle:{},
          labels: {
            style: {
              fontSize: '10px'
            },
            formatter: !!!formatterX ? (value) => {
              return value;
            } : formatterX,
          },
        },
        yaxis: {
          max: max?max:null,
          title: {
            text: yAxisLabel
          },
          labels: {
            formatter: !!!formatterY ? (value) => {
              return value;
            } : formatterY,
          }
        },
      }
    };
  }

  function FormatDistance(distance) {
    let d = distance * 1;
    return d <= 176 ? d + ' yards' : (Math.round(d * 100 / 1760) / 100 + ' miles');
  }

  var onPrintFinished = function (printed) {
    setTab(tabFootfall);
  }

  function ShareReport() {
    if (reportNum === null || !process.env.hasOwnProperty("REACT_APP_PROD_PUBLIC_URL")) {
      toast.error(formatErrors(["Something goes wrong"]), {position: toast.POSITION.BOTTOM_RIGHT});
      return
    }

    Track(dispatch, profile, "Report shared");
    navigator.clipboard.writeText(process.env["REACT_APP_PROD_PUBLIC_URL"]+"/location-report/"+reportNum).then(() => {
      toast.success("Report link copied", {position: toast.POSITION.BOTTOM_RIGHT});
    }).catch(() => {
      toast.error(formatErrors(["Something goes wrong"]), {position: toast.POSITION.BOTTOM_RIGHT});
    })
  }

  function print() {
    if (tab === '') {
      return;
    }
    setTab('');

    setTimeout(() => {
      onPrintFinished(window.print());
    }, 1000);
  }

  function OrderReport() {
    setLoading(true);
    dispatch(orderReportAction({
      address: data.address,
      latitude: data.lat,
      longitude: data.lng,
      postcode: data.postcode,
      hex: data.hex,
      geocoder_mode: data.geocoder_mode,
    })).then(res => {
      // document.location.href = "https://buy.stripe.com/test_aEU188aWV5G8ddm8ww?client_reference_id="+res.id
      document.location.href = "https://buy.stripe.com/eVa7ulgMF4RhbEAdRs?client_reference_id="+res.id
      setLoading(false);
    }).catch(() => {
      toast.error(formatErrors(["Something goes wrong"]), {position: toast.POSITION.BOTTOM_RIGHT});
      setLoading(false);
    });
  }

  return <React.Fragment>
    <div className={"loader-screen"} hidden={!loading}>
      <div>Loading ...</div>
    </div>

    {showLoginPopup && <div className={"report-login-popup"}>
      <LoginWidget handleClose={HandleCloseLoginPopup} reportSess={reportSess}/>
    </div>}

    {tab !== '' && <MyAppBar
      newReportNoAuth={tab !== '' && !isAuth && placeName === ""}
      newReportAuth={tab !== '' && isAuth && (freeUses === null || freeUses > 0) && reportId !== 'xxx' && ready}
      showReportCnt={tab !=='' && isAuth && freeUses !== null}
      buyFullSub={tab !== '' && isAuth && freeUses !== null}
      disableLogo={!!placeName}
      showGuestInterface = {!isAuth}
      reportSess = {reportSess}
    />}

    <Helmet key={htag}>
      <title>Location report{!!address ? " - " + address : ""} - Getplace.io</title>
      <meta name="description" content={"Get a detailed location report" + (!!address ? " for " + address : '') + " Our report offers in-depth analysis of foot traffic and customer behavior, helping you make informed business decisions. Get your location report now"} />
    </Helmet>

    <br />
    {ready && <React.Fragment>
      <Container>
        <Grid columns='equal'>
          <Grid.Row className={'report-tab-grid'}>
            <Grid.Column className={"center aligned"}>
              { !!!placeName && <h1>Location report</h1>}
              { !!placeName && <h1 style={{whiteSpace: "pre", wordSpacing: "5px"}}>Location report for{"\n"}{placeName}</h1>}
              {!!address && geocoderMode ===0 && <h4>Address: {address}</h4>}
              { geocoderMode === 1 && !!subPostcode && <h4>Postcode: {subPostcode}</h4>}

              <div className={'report-tab-container'}>

                  <span className={tab === tabSnapshot ? 'active' : ''}
                        onClick={() => tab !== tabSnapshot && setTab(tabSnapshot)}>Snapshot</span>

                { displayMode === "footfall" && <React.Fragment>
                  <span className={tab === tabFootfall ? 'active' : ''}
                        onClick={() => tab !== tabFootfall && setTab(tabFootfall)}>Footfall</span>
                  <span className={tab === tabTraffic ? 'active' : ''}
                        onClick={() => tab !== tabTraffic && setTab(tabTraffic)}>Vehicle Traffic</span>
                  <span className={tab === tabDemographic ? 'active' : ''}
                        onClick={() => tab !== tabDemographic && setTab(tabDemographic)}>Demographics</span>
                </React.Fragment>}

                { displayMode === "demographic" && <React.Fragment>
                  <span className={tab === tabDemographic ? 'active' : ''}
                        onClick={() => tab !== tabDemographic && setTab(tabDemographic)}>Demographics</span>
                  <span className={tab === tabFootfall ? 'active' : ''}
                        onClick={() => tab !== tabFootfall && setTab(tabFootfall)}>Footfall</span>
                  <span className={tab === tabTraffic ? 'active' : ''}
                        onClick={() => tab !== tabTraffic && setTab(tabTraffic)}>Vehicle Traffic</span>
                </React.Fragment>}

                <span className={tab === tabAmenities ? 'active' : ''}
                      onClick={() => tab !== tabAmenities && setTab(tabAmenities)}>Amenities</span>
                
                {reportId !== 'xxx' && <span className={tab === tabCompetitors ? 'active' : ''}
                                             onClick={() => tab !== tabCompetitors && setTab(tabCompetitors)}>Competitors</span>}
                {showPano && <span className={tab === tabPanorama ? 'active' : ''}
                      onClick={() => tab !== tabPanorama && setTab(tabPanorama)}>Street View</span>}
                
                {reportId !== 'xxx' && !!pricingReport && (pricingReport.hasOwnProperty(macId) || pricingReport.hasOwnProperty(bkId)) && <span className={tab === tabPricing ? 'active' : ''}
                      onClick={() => tab !== tabPricing && setTab(tabPricing)}>Pricing</span>}

                {showDelivery && <span className={tab === tabDelivery ? 'active' : ''}
                                   onClick={() => tab !== tabDelivery && setTab(tabDelivery)}>Delivery</span>}

                {!!realEstateReport && <span className={tab === tabRealEstate ? 'active' : ''}
                                             onClick={() => tab !== tabRealEstate && setTab(tabRealEstate)}>Real Estate</span>}

                {tab !== '' && <div className={'export-button ' + (tab === '' ? 'active' : '')}
                                    onClick={() => ShareReport()}>Share report</div>}
              </div>
              {(!showPromo || tab === '') && <div className={'report-tab-header'}></div>}

            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      {showPromo && tab !== '' && placeName === "" && <div className={'feedback-promo'}>
        <a href={'https://bit.ly/401Tuvp'} target={'_blank'} rel="noreferrer">Give Feedback</a>Share the feedback and <b>get 50% off</b> the first month
        <Icon size={'big'} name={'window close'} onClick={() => {
          setShowPromo(false);
          setCookie('advSub', '1', 30);
        }}/>
      </div>}
      {showPromo2 && tab !== '' && placeName !== "" && <div className={'feedback-promo'}>
        <a href={'https://semrush.typeform.com/to/ML9e0JYt'} target={'_blank'} rel="noreferrer">Give Feedback</a>Let us know how useful this report is for you
        <Icon size={'big'} name={'window close'} onClick={() => {
          setShowPromo2(false);
          setCookie('advSub2', '1', 30);
        }}/>
      </div>}
      <br/><br/>

      <Container className={"location-report-grid"}>
        <Grid columns='equal'>
          {(tab === tabFootfall || tab === '') && <React.Fragment>
            <Grid.Row>
              <Grid.Column width={7} className={displayMode==="footfall" && !!placeName?"footfall-banner":""}>
                <h2 className={"location-report-h2-large"}>Footfall analysis</h2>
              </Grid.Column>
              <Grid.Column width={9}>
                {geocoderMode === 0 && <span className={'footfall-map-header'}>Footfall Heat Map for ~1 mile radius</span>}
                {(!profile || [ROLE_SUBSCRIBER_REPORTS].indexOf(profile?.role_id) >= 0) && <a href={'https://getplace.io/heatmap'} target={'_blank'} className={'footfall-map-info'} rel="noreferrer">Get access to full map</a>}
                <div className={'report-map-box'} id={'footfall-map-box'}
                     ref={(el) => (mapFootfallContainer.current = el)}>

                </div>
                <div className={'map-legend-mini'}>
                  <div className={'legend-gradient-items'}>
                    <div style={{width: '50%'}}>No traffic</div>
                    <div style={{width: '50%'}}>Very high traffic</div>
                  </div>
                  <div className={"legend-gradient-container"}>
                    {trafficGradient.map((itm, idx) => {
                      return <div key={idx} style={{backgroundColor:itm}}/>;
                    })}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <DateChanger dates={dates} handleChange={setActiveDate} activeDate={activeDate} />

                {/*<div className={"report-date-picker"}>
                  <span className={"report-date-picker-text"}>Data for{geocoderMode===1?": "+dates[dates.length-1].name:":"}</span>
                  {geocoderMode === 0 && <div className={"report-tab-container report-date-picker-dates"}>
                    {dates.map((item, idx) => {
                      return <span key={idx} className={activeDate === idx ? 'active' : ''}
                                   onClick={() => activeDate !== idx && setActiveDate(idx)}>{item.name}</span>
                    })}
                  </div> }
                </div>*/}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
              <h2>Footfall average per month</h2>
              <Chart options={avgFootfall.options} series={avgFootfall.series} type={"bar"} height={250}/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h2>Daily Footfall Average
                  {/* — <span className={"magenta"}>{CalcAvgToText(footfallMonthTotalAvg)}</span>
                <span className={"footfall-tooltip"}>&#9432;
                  <span>
                    <b>Low</b> Less than 5,000 people/day<br/>
                    <b>Medium</b> 5,001 - 10,000 people/day<br/>
                    <b>High</b> 10,000 - 20,000 people/day<br/>
                    <b>Very High</b> More than 20,000 people/day
                  </span>
                </span>
                </span>*/}
                </h2>
                <p className={"location-report-subh"}>Average number of people walking in the area, {dates[activeDate].name}</p>

                <br />

                <DailyFootfallAverage
                  avgObj={footfallVisitationAvg}
                  workdayAvg={avgWorkday}
                  weekendAvg={avgWeekend}
                  avgTotal={footfallMonthTotalAvg}
                  month={monthsShort[dates[activeDate].month]}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <h2>Monthly footfall distribution</h2>
                <p className={"location-report-subh"}>Number of people walking in the area per day of the month, {dates[activeDate].name}</p>
                {footfallMonth !== null &&
                  <Chart options={footfallMonth.options} series={footfallMonth.series} type={"line"} height={400}/>}
                {footfallMonth === null && <span className={'location-report-text'}>No data available</span>}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h2>Weekday footfall distribution</h2>
                <p className={"location-report-subh"}>Average number of people walking in the area per day of the week, {dates[activeDate].name}</p>
                {footfallAvgWeek !== null &&
                  <Chart options={footfallAvgWeek.options} series={footfallAvgWeek.series} type={"bar"} height={400}/>}
                {footfallAvgWeek === null && <span className={'location-report-text'}>No data available</span>}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h2>Day footfall distribution </h2>
                <p className={"location-report-subh"}>Average number of people walking in the area per hour of the day, {dates[activeDate].name}</p>
                <br />
                {!!ffDayList && ffDayList.length > 0 && <div className={"location-report-day-selection-flex"}>
                  <span>Show data for:</span>
                  {ffDayList.map((itm, idx) => {
                    return <span key={idx} onClick={() => {setFfDay(itm.value)}} className={"itm "+(ffDay===itm.value?"active":"")}>{itm.text}</span>
                  })}
                </div>}
                {ffDay === 0 && footfallAvgDayAll !== null &&
                  <Chart options={footfallAvgDayAll.options} series={footfallAvgDayAll.series} type={"bar"}
                         height={400}/>}
                {ffDay === 0 && footfallAvgDayAll === null && <span className={'location-report-text'}>No data available</span>}

                {ffDay > 0 && !!footfallAvgDayByDay[ffDay-1] &&
                  <Chart options={footfallAvgDayByDay[ffDay-1].options} series={footfallAvgDayByDay[ffDay-1].series} type={"bar"}
                         height={400}/>}
                {ffDay > 0 && !footfallAvgDayByDay[ffDay-1] && <span className={'location-report-text'}>No data available</span>}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Footfall/>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>}

          {(tab === tabTraffic || tab === '') && <React.Fragment>

            <Grid.Row>
              <Grid.Column>
                <h2>Vehicle traffic analysis</h2>
                <div className={"report-date-picker"}>
                  <span className={"report-date-picker-text"}>Data for{geocoderMode===1?": "+dates[dates.length-1].name:":"}</span>
                  {geocoderMode === 0 && <div className={"report-tab-container report-date-picker-dates"}>
                    {dates.map((item, idx) => {
                      return <span key={idx} className={activeDate === idx ? 'active' : ''}
                                   onClick={() => activeDate !== idx && setActiveDate(idx)}>{item.name}</span>
                    })}
                  </div>}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h3>Average amount</h3>
                <br />
                <Grid columns={"equal"}>
                  <Grid.Row>
                    <Grid.Column>
                      <h3>{footfallMonthTotalAvgN} vehicles</h3>
                      per day
                    </Grid.Column>
                    <Grid.Column>
                      <h3>{avgWorkdayN} vehicles</h3>
                      per workday
                    </Grid.Column>
                    <Grid.Column>
                      <h3>{avgWeekendN} vehicles</h3>
                      per weekend
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h2>Vehicle traffic month pattern</h2>
                {footfallMonthN !== null &&
                  <Chart options={footfallMonthN.options} series={footfallMonthN.series} type={"line"} height={400}/>}
                {footfallMonthN === null && <span className={'location-report-text'}>No data available</span>}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h2>Vehicle traffic average week pattern</h2>
                {footfallAvgWeekN !== null &&
                  <Chart options={footfallAvgWeekN.options} series={footfallAvgWeekN.series} type={"bar"}
                         height={400}/>}
                {footfallAvgWeekN === null && <span className={'location-report-text'}>No data available</span>}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className={"location-report-day-selection"}>
                  <h2>Vehicle traffic average day pattern</h2><Dropdown selection value={ffDayN} options={ffDayList} onChange={(e, v) => setFfDayN(v.value)}/>
                </div>
                {footfallAvgDayAllN !== null && ffDayN === 0 &&
                  <Chart options={footfallAvgDayAllN.options} series={footfallAvgDayAllN.series} type={"bar"}
                         height={400}/>}
                {ffDayN === 0 && footfallAvgDayAllN === null && <span className={'location-report-text'}>No data available</span>}

                {ffDayN > 0 && !!footfallAvgDayByDayN[ffDayN-1] &&
                  <Chart options={footfallAvgDayByDayN[ffDayN-1].options} series={footfallAvgDayByDayN[ffDayN-1].series} type={"bar"}
                         height={400}/>}
                {ffDayN > 0 && !footfallAvgDayByDayN[ffDayN-1] && <span className={'location-report-text'}>No data available</span>}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Footfall/>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>}

          {(tab === tabDemographic || tab === '') &&
            <DemographicsTab
              data={oc21}
              inRadius={inRadius}
              income={{rate:medianIncomeRate, wealth: areaWealth, median: medianIncome}}
              crime={{rating: crimeRateRating, types: crimeTypes}}
            />
          }

          {(tab === tabCompetitors || tab === '') && !useCU && <React.Fragment>
            <Grid.Row>
              <Grid.Column width={16}>
                <h2>Competitive Analysis</h2>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className={'report-map-box'} id={'competitors-map-box'}
                     ref={(el) => (mapCompetitorsContainer.current = el)}></div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h2>Top closest competitors</h2>
                {!!competitors && competitors.length > 0 && <React.Fragment>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Distance<br/>(yards)</Table.HeaderCell>
                        <Table.HeaderCell>Rating<br/>(out of 5)</Table.HeaderCell>
                        <Table.HeaderCell>Review Number</Table.HeaderCell>
                        <Table.HeaderCell>Price Level<br/>(out of 5)</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {competitors.map((item, idx) => {
                        return <Table.Row key={idx}>
                          <Table.Cell>{idx + 1}</Table.Cell>
                          <Table.Cell>{item.name}</Table.Cell>
                          <Table.Cell>{item.type.join(', ')}</Table.Cell>
                          <Table.Cell>{item.distance}</Table.Cell>
                          <Table.Cell>{item.rating===-1?"n/a":item.rating}</Table.Cell>
                          <Table.Cell>{item.review_count===500?"500+":item.review_count}</Table.Cell>
                          <Table.Cell>{item.price_level === -1 ? 'n/a' : item.price_level}</Table.Cell>
                        </Table.Row>;
                      })}
                    </Table.Body>
                  </Table>
                </React.Fragment>}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Competitors/>
              </Grid.Column>
            </Grid.Row>
            <div className={'pb-print'}/>
          </React.Fragment>}

          {(tab === tabCompetitors || tab === '') && useCU && !!reportNum &&
            <CompetitorsTab
              ref={cuContainer}
              lat={data.lat}
              lng={data.lng}
              loading={loading}
              handleLoading={setLoading}
              filter={cuFilter}
              handleFilter={HandleCuFilter}
              competitors={cuCompetitors}
              handleCompetitors={setCUCompetitors}
              types={cuTypes}
            />
          }

          {(tab === tabAmenities || tab === '') && <React.Fragment>
            <Grid.Row>
              <Grid.Column>
                <h2>Amenities Analysis</h2>
                within 1 mile
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className={'report-map-box'} id={'amenities-map-box'}
                     ref={(el) => (mapAmenitiesContainer.current = el)}></div>
              </Grid.Column>
            </Grid.Row>
            <h2>Transport</h2>
            <Grid.Row>
              <Grid.Column>
                <h3 className={'amenities-header subway_h_ico'}>Underground stations</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{subwayCnt > 0 ? subwayCnt : 'none'}</b></div>
                <br/>
                {subwayCnt > 0 && <span className={'location-report-text'}>Top closest:</span>}
                {!!subway && subway.length > 0 && <React.Fragment>
                  <div className={'top-5-header'}>
                    <span>Name</span>
                    <span>Distance (yards)</span>
                  </div>
                  {subway.map((item, idx) => {
                    return idx >= 20 ? null : <div key={idx} className={'amenities-item'}>
                      <span>{item.name === '' ? 'Subway station' : item.name}</span><span>{item.distance}</span></div>;
                  })}
                </React.Fragment>}
              </Grid.Column>
              <Grid.Column>
                <h3 className={'amenities-header train_h_ico'}>Train stations</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{trainstationCnt > 0 ? trainstationCnt : 'none'}</b></div>
                <br/>
                {trainstationCnt > 0 && <span className={'location-report-text'}>Top closest:</span>}
                {!!trainstation && trainstation.length > 0 && <React.Fragment>
                  <div className={'top-5-header'}>
                    <span>Name</span>
                    <span>Distance (yards)</span>
                  </div>
                  {trainstation.map((item, idx) => {
                    return idx >= 20 ? null : <div key={idx} className={'amenities-item'}>
                      <span>{item.name === '' ? 'Train station' : item.name}</span><span>{item.distance}</span></div>;
                  })}
                </React.Fragment>}
              </Grid.Column>
              <Grid.Column>
                <h3 className={'amenities-header parking_h_ico'}>Parkings</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{parkingCnt > 0 ? parkingCnt : 'none'}</b></div>
              </Grid.Column>
            </Grid.Row>

            <h2>Retails</h2>
            <Grid.Row>
              <Grid.Column>

                <h3 className={'amenities-header supermarket_h_ico'}>Supermarkets</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{supermarketsCnt > 0 ? supermarketsCnt : 'none'}</b></div>
                <br/>
                {supermarketsCnt > 0 && <span className={'location-report-text'}>Top closest:</span>}
                {!!supermarkets && supermarkets.length > 0 && <React.Fragment>
                  <div className={'top-5-header'}>
                    <span>Name</span>
                    <span>Distance (yards)</span>
                  </div>
                  {supermarkets.map((item, idx) => {
                    return idx >= 20 ? null : <div key={idx} className={'amenities-item'}>
                      <span>{item.name === '' ? 'Supermarket' : item.name}</span><span>{item.distance}</span></div>;
                  })}
                </React.Fragment>}
              </Grid.Column>
              <Grid.Column>
                <h3 className={'amenities-header grocery_h_ico'}>Grocery stores</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{groceryCnt > 0 ? groceryCnt : 'none'}</b></div>
                <br/>
                {groceryCnt > 0 && <span className={'location-report-text'}>Top closest:</span>}
                {!!grocery && grocery.length > 0 && <React.Fragment>
                  <div className={'top-5-header'}>
                    <span>Name</span>
                    <span>Distance (yards)</span>
                  </div>
                  {grocery.map((item, idx) => {
                    return idx >= 20 ? null : <div key={idx} className={'amenities-item'}>
                      <span>{item.name === '' ? 'Store' : item.name}</span><span>{item.distance}</span></div>;
                  })}
                </React.Fragment>}
              </Grid.Column>
              <Grid.Column>
                <h3 className={'amenities-header mall_h_ico'}>Mall</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{mallsCnt > 0 ? mallsCnt : 'none'}</b></div>
                <br/>
                {mallsCnt > 0 && <span className={'location-report-text'}>Top closest:</span>}
                {!!malls && malls.length > 0 && <React.Fragment>
                  <div className={'top-5-header'}>
                    <span>Name</span>
                    <span>Distance (yards)</span>
                  </div>
                  {malls.map((item, idx) => {
                    return idx >= 20 ? null : <div key={idx} className={'amenities-item'}>
                      <span>{item.name === '' ? 'Mall' : item.name}</span><span>{item.distance}</span></div>;
                  })}
                </React.Fragment>}
              </Grid.Column>
            </Grid.Row>

            <h2>Education facilities</h2>
            <Grid.Row>
              <Grid.Column>
                <h3 className={'amenities-header college_h_ico'}>Colleges</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{collegesCnt > 0 ? collegesCnt : 'none'}</b></div>
                <br/>
                {collegesCnt > 0 && <span className={'location-report-text'}>Top closest:</span>}
                {!!colleges && colleges.length > 0 && <React.Fragment>
                  <div className={'top-5-header'}>
                    <span>Name</span>
                    <span>Distance (yards)</span>
                  </div>
                  {colleges.map((item, idx) => {
                    return idx >= 20 ? null : <div key={idx} className={'amenities-item'}>
                      <span>{item.name === '' ? 'College' : item.name}</span><span>{item.distance}</span></div>;
                  })}
                </React.Fragment>}
              </Grid.Column>
              <Grid.Column>
                <h3 className={'amenities-header school_h_ico'}>Schools</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{schoolsCnt > 0 ? schoolsCnt : 'none'}</b></div>
                <br/>
                {schoolsCnt > 0 && <span className={'location-report-text'}>Top closest:</span>}
                {!!schools && schools.length > 0 && <React.Fragment>
                  <div className={'top-5-header'}>
                    <span>Name</span>
                    <span>Distance (yards)</span>
                  </div>
                  {schools.map((item, idx) => {
                    return idx >= 20 ? null :
                      <div key={idx} className={'amenities-item'}><span>{item.name === '' ? 'School' : item.name}</span><span>{item.distance}</span>
                      </div>;
                  })}
                </React.Fragment>}
              </Grid.Column>
              <Grid.Column>
                <h3 className={'amenities-header nursery_h_ico'}>Nurseries</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{nurseriesCnt > 0 ? nurseriesCnt : 'none'}</b></div>
                <br/>
                {nurseriesCnt > 0 && <span className={'location-report-text'}>Top closest:</span>}
                {!!nurseries && nurseries.length > 0 && <React.Fragment>
                  <div className={'top-5-header'}>
                    <span>Name</span>
                    <span>Distance (yards)</span>
                  </div>
                  {nurseries.map((item, idx) => {
                    return idx >= 20 ? null : <div key={idx} className={'amenities-item'}>
                      <span>{item.name === '' ? 'Nursery' : item.name}</span><span>{item.distance}</span></div>;
                  })}
                </React.Fragment>}
              </Grid.Column>
            </Grid.Row>

            <h2>Utilities</h2>
            <Grid.Row>
              <Grid.Column>
                <h3 className={'amenities-header post_h_ico'}>Post offices</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{postOfficesCnt > 0 ? postOfficesCnt : 'none'}</b></div>
                <br/>
                {postOfficesCnt > 0 && <span className={'location-report-text'}>Top closest:</span>}
                {!!postOffices && postOffices.length > 0 && <React.Fragment>
                  <div className={'top-5-header'}>
                    <span>Name</span>
                    <span>Distance (yards)</span>
                  </div>
                  {postOffices.map((item, idx) => {
                    return idx >= 20 ? null :
                      <div key={idx} className={'amenities-item'}><span>{item.name === '' ? 'Office' : item.name}</span><span>{item.distance}</span>
                      </div>;
                  })}
                </React.Fragment>}
              </Grid.Column>
              <Grid.Column>
                <h3 className={'amenities-header bank_h_ico'}>Banks</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{banksCnt > 0 ? banksCnt : 'none'}</b></div>
                <br/>
                {banksCnt > 0 && <span className={'location-report-text'}>Top closest:</span>}
                {!!banks && banks.length > 0 && <React.Fragment>
                  <div className={'top-5-header'}>
                    <span>Name</span>
                    <span>Distance (yards)</span>
                  </div>
                  {banks.map((item, idx) => {
                    return idx >= 20 ? null :
                      <div key={idx} className={'amenities-item'}><span>{item.name === '' ? 'School' : item.name}</span><span>{item.distance}</span>
                      </div>;
                  })}
                </React.Fragment>}
              </Grid.Column>
              <Grid.Column>
                <h3 className={'amenities-header atm_h_ico'}>ATM</h3>
                <div className={'location-report-text'}>Total: <b
                  className={'magenta'}>{atmCnt > 0 ? atmCnt : 'none'}</b></div>
                <br/>
                {atmCnt > 0 && <span className={'location-report-text'}>Top closest:</span>}
                {!!atm && atm.length > 0 && <React.Fragment>
                  <div className={'top-5-header'}>
                    <span>Name</span>
                    <span>Distance (yards)</span>
                  </div>
                  {atm.map((item, idx) => {
                    return idx >= 20 ? null : <div key={idx} className={'amenities-item'}>
                      <span>{item.name === '' ? 'ATM' : item.name}</span><span>{item.distance}</span></div>;
                  })}
                </React.Fragment>}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Amenities/>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>}
          
          {showPano && <React.Fragment>
            <Grid.Row style={{display:(tab === tabPanorama || tab === tabSnapshot)?"block":"none"}}>
              <Grid.Column>
                {tab===tabSnapshot && <h2 className={"location-report-ss-header"}>Report snapshot</h2>}
                <div className={tab === tabSnapshot?"snapshot-pano":""}>
                  <h2>Street View{tab === tabSnapshot && <span onClick={() => setTab(tabDelivery)}>Check Delivery Zones</span>}</h2>
                  <div className={"pano_container"}>
                    <Wrapper apiKey={gKey}>
                      <Panorama
                        center={{lat: data.lat, lng: data.lng}}
                        ref={panoramaContainer}
                        init={initPano}
                        setInit={setInitPano}
                        small={tab===tabSnapshot}
                      />
                    </Wrapper>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>}

          {tab === tabPricing && <React.Fragment>
            <Grid.Row>
              <Grid.Column>
                <h2>Big Chains Pricing</h2>
                in 1 mile radius
                <br />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p style={{fontSize:"18px"}}>Based on many factors, including those in this report, McDonald's and Burger King adapt their prices for each restaurant.<br/><br/>
                  Below, you can see how the prices of the closest restaurants compare to those of the median across the city.</p>
              </Grid.Column>
              <Grid.Column width={11}>
                <div className={'report-map-box'} id={'pricing-map-box'}
                     ref={(el) => (mapPricingContainer.current = el)}>

                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {!!pricingReport && pricingReport.hasOwnProperty(macId) && !!pricingMenuItems && pricingMenuItems.hasOwnProperty(macId) &&
                  <ReportPricePulse dGeo={dGeo} brand={macId} menuItems={pricingMenuItems[macId]} report={pricingReport[macId]} title="McDonald's" defaultCode={"big_mac"}/>}
                <br /><br />

                {!!pricingReport && pricingReport.hasOwnProperty(bkId) && !!pricingMenuItems && pricingMenuItems.hasOwnProperty(bkId) &&
                  <ReportPricePulse dGeo={dGeo} brand={bkId} menuItems={pricingMenuItems[bkId]} report={pricingReport[bkId]} title="Burger King" defaultCode={"whopper"}/>}
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>}

          {showDelivery && tab === tabDelivery && <React.Fragment>
            <Grid.Row>
              <Grid.Column>
                <h2>Delivery zones</h2>
                <p>Driving, cycling, walking in 15 minutes</p>
                <div className={'delivery-map-box'} id={'delivery-map-box'}
                     ref={(el) => (mapDeliveryContainer.current = el)}>

                </div>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>}

          {!!realEstateReport && tab === tabRealEstate &&
            <RealEstateReport
              tab={realEstateTab}
              handleChange={setRealEstateTab}
              location={realEstateLocation}
              report={realEstateReport}
            />}

          {tab === tabSnapshot && <Snapshot report={snapshot} handleChange={setTab}/>}

          {tabs && <LocationReportNavigation
            tabs={tabs}
            activeTab={tab}
            displayMode={displayMode}
            handleChange={setTab}
          />}


            </Grid>
      </Container></React.Fragment>}
    {ready === false && <Container className="report-container">
      <Grid columns='equal'>
        <Grid.Row>
          <Grid.Column className={"four wide column location-report-ctypes"}>
            <label className={"bold search-label"}>Competitors category</label>
            <Dropdown selection value={competitorsCategory} options={compTypes} onChange={(e,v) => {setCompetitorsCategory(v.value);}}/>
          </Grid.Column>
          <Grid.Column className={"eight wide column"}>
            <label className={"bold search-label"}>
              <Button.Group size={"medium"}>
                <Button active={geocoderMode===geocoderMenu[0].value} onClick={() => {setGeocoderMode(0);}}>{geocoderMenu[0].text}</Button>
                <Button.Or />
                <Button active={geocoderMode===geocoderMenu[1].value} onClick={() => {setGeocoderMode(1);}}>{geocoderMenu[1].text}</Button>
              </Button.Group>
            </label>
            <div style={{paddingTop: '4px'}} id='geocoder-container'/>
          </Grid.Column>
          <Grid.Column>
            <label className={"bold search-label"} style={{display:'block'}}>&nbsp;</label>
            {isAuth && (profile?.role_id!==ROLE_USER || profile.free_service_use > 0) && !!data && data.hex !== '' && data.postcode !== '' && !!!loading &&
              <Button className={'search-button gb-magenta'}
                      onClick={() => {
                        Track(dispatch, profile, "Report Requested");
                        GetReports('', data);
                      }}>GET REPORT
              </Button>}

            {isAuth && (profile?.role_id===ROLE_USER && profile.free_service_use === 0) && !!data && data.hex !== '' && data.postcode !== '' && !!!loading &&
                <Button className={'search-button gb-magenta'}
                        onClick={() => {
                          OrderReport()
                        }}>GET REPORT FOR £99
                </Button>}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid columns='equal'>
        {!!isAuth && [ROLE_ADMIN, ROLE_MANAGER, ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_REPORTS, ROLE_USER].indexOf(profile?.role_id) >= 0 && !reportId && <Grid.Row>
          <Grid.Column>
            <h2>Reports history</h2>
            {profile?.role_id===ROLE_USER && myReportsTotal === 0 && profile.free_service_use === LIMIT_FREE_SERVICE_USE && <Consultation />}

            {(profile?.role_id!==ROLE_USER || profile.free_service_use < LIMIT_FREE_SERVICE_USE) && myReportsTotal === 0 && <React.Fragment>



              <Table celled striped sortable compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Address</Table.HeaderCell>
                    <Table.HeaderCell>Competitors category</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Link</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body className={"report-table-placeholder"}>
                  <Table.Row>
                    <Table.Cell colSpan={4}>

                      <div className={"pcline"}>
                        <div className={"pcol pcol1"} />
                        <div className={"pcol pcol2"} />
                        <div className={"pcol pcol3"} />
                        <div className={"pcol pcol4"} />
                        <div className={"pcol pcol5"} />
                      </div>
                      <div className={"banner"}>
                        <div className={"doc-icon"} />< br/>
                        You haven't requested any report yet.<br />
                        To begin, enter an address or postcode<br />
                        in the location search field above, or <br />
                        you can explore <a href={"/poi-map"}>Competitive Areas</a><br />
                      </div>
                      <div className={"pcline"}>
                        <div className={"pcol pcol1"} />
                        <div className={"pcol pcol2"} />
                        <div className={"pcol pcol3"} />
                        <div className={"pcol pcol4"} />
                        <div className={"pcol pcol5"} />
                      </div>
                      <div className={"pcline"}>
                        <div className={"pcol pcol1"} />
                        <div className={"pcol pcol2"} />
                        <div className={"pcol pcol3"} />
                        <div className={"pcol pcol4"} />
                        <div className={"pcol pcol5"} />
                      </div>
                      <div className={"pcline"}>
                        <div className={"pcol pcol1"} />
                        <div className={"pcol pcol2"} />
                        <div className={"pcol pcol3"} />
                        <div className={"pcol pcol4"} />
                        <div className={"pcol pcol5"} />
                      </div>
                      <div className={"pcline"}>
                        <div className={"pcol pcol1"} />
                        <div className={"pcol pcol2"} />
                        <div className={"pcol pcol3"} />
                        <div className={"pcol pcol4"} />
                        <div className={"pcol pcol5"} />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <div className={"report-table-placeholder"}></div>
            </React.Fragment>}

            {myReportsTotal > 0 && <React.Fragment><Table celled striped sortable compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Address</Table.HeaderCell>
                  <Table.HeaderCell>Competitors category</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Link</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {myReports.map((i) => {
                  return <Table.Row key={i.id}>
                    <Table.Cell>{i.address}</Table.Cell>
                    <Table.Cell>{SearchCategory(i.competitors_category)}</Table.Cell>
                    <Table.Cell>{i.created}</Table.Cell>
                    <Table.Cell>{i.report_num!==""?<a target={"_blank"} href={"/location-report/"+i.report_num} rel="noreferrer">{i.report_num}</a>:"-"}</Table.Cell>
                  </Table.Row>;
                })}
              </Table.Body>
            </Table>

              <Paging total={myReportsTotal} page={myReportsFilter.page} limit={myReportsFilter.limit} handleChange={handleChangeFilter}/></React.Fragment>}
          </Grid.Column>
        </Grid.Row>}
      </Grid>
    </Container>}
    <br />
    {/*showWelcome && <WelcomePasswordChange active={true} profile={profile} handleChange={setShowWelcome}/>*/}
  </React.Fragment>;
}

const Panorama = forwardRef(function(props, ref) {
  // const ref = useRef(null);

  useEffect(() => {

    if (!props.init) {
      let sv = new window.google.maps.StreetViewService();
      let panoRequest = {
        location: props.center,
        preference: window.google.maps.StreetViewPreference.NEAREST,
        radius: 50,
        source: window.google.maps.StreetViewSource.OUTDOOR
      };

      sv.getPanorama(panoRequest).then(res => {
        let pano = new window.google.maps.StreetViewPanorama(
          ref.current.pano,
          {
            pano: res.data.location.pano,
          }
        );

        const map = new window.google.maps.Map(ref.current.map, {
          center: props.center,
          zoom: 18.5,
        });

        new window.google.maps.Marker({
          position: props.center,
          map,
        });

        map.setStreetView(pano);

        props.setInit(true);
      }).catch((err) => {
        ref.current.pano.id = "pano_not_found";
        ref.current.pano.innerHTML = `<span>No street view found</span>`
        ref.current.map.remove();
        props.setInit(true);
      })
    }
  });

  return <React.Fragment>
    <div className={props.small?"pano-small":""}>
      <div ref={(el) => {ref.current.pano = el}} id="pano"/>
      <div ref={(el) => {ref.current.map = el}} id="pano-map" />
    </div>
  </React.Fragment>;
});

export default LocationReport
